import { Component, OnInit, Inject, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Animacao } from '../../core/tools/animacao';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

declare const MercadoPago: any;

/* Services */

import { AuthService } from '../../core/services/auth/auth.service';
import { ClienteService } from '../../core/services/clientes/cliente.service';
import { ConfiguracaoService } from '../../core/services/configuracoes/configuracao.service';
import { FormaPagamentoService } from '../../core/services/formas-pagamento/forma-pagamento.service';
import { PedidoService } from '../../core/services/pedidos/pedido.service';
import { PedidoItensService } from '../../core/services/pedidos/pedido-itens.service';
import { PrazoPagamentoService } from '../../core/services/prazos-pagamento/prazo-pagamento.service';
import { PrazoPagamentoClienteService } from '../../core/services/prazos-pagamento/prazo-pagamento-cliente.service';
import { ProdutoService } from '../../core/services/produtos/produto.service';
import { TipoPedidoService } from '../../core/services/tipos/tipo-pedido.service';

/* Models */

import { Cliente } from '../../core/models/clientes/cliente';
import { ClienteLocalEntrega } from '../../core/models/clientes/cliente-local-entrega';
import { Configuracao } from '../../core/models/configuracoes/configuracao';
import { ConfiguracaoBanner } from '../../core/models/configuracoes/configuracao-banner';
import { FormaPagamento } from '../../core/models/formas-pagamento/forma-pagamento';
import { MeiosPagamentoCartoesMercadoPagoRetorno } from '../../core/models/meios-pagamento/cartoes/mercado-pago/meios-pagamento-cartoes-mercado-pago-retorno';
import { Pedido } from '../../core/models/pedidos/pedido';
import { PedidoBonificacaoRetorno } from '../../core/models/pedidos/pedido-bonificacao-retorno';
import { PedidoItens } from '../../core/models/pedidos/pedido-itens';
import { PrazoPagamento } from '../../core/models/prazos-pagamento/prazo-pagamento';
import { PrazoPagamentoCliente } from '../../core/models/prazos-pagamento/prazo-pagamento-cliente';
import { ProdutoCategoria } from '../../core/models/produtos/produto-categoria';
import { ProdutoDisponibilidade } from '../../core/models/produtos/produto-disponibilidade';
import { ProdutoFichaTecnica } from '../../core/models/produtos/produto-ficha-tecnica';
import { ProdutoGrupo } from '../../core/models/produtos/produto-grupo';
import { ProdutoGrupoNome } from '../../core/models/produtos/produto-grupo-nome';
import { ProdutoPromocional } from '../../core/models/produtos/produto-promocional';
import { ProdutoPromocionalAgrupado } from '../../core/models/produtos/produto-promocional-agrupado';
import { ProdutoSegmento } from '../../core/models/produtos/produto-segmento';
import { ProdutoTabelaPromocaoQtde } from '../../core/models/produtos/produto-tabela-promocao-qtde';
import { ProdutoFoto } from '../../core/models/produtos/produto-foto';
import { TipoPedido } from '../../core/models/tipos/tipo-pedido';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css'],
  preserveWhitespaces: false,
  animations: [Animacao]
})

export class ProdutosComponent implements OnInit {
  /* Avisos */

  exibirAviso: boolean = false;

  /* Base URL */

  baseUrl: string;

  /* Categorias (Fixas) */

  aviseme: string = "AVISE-ME";
  campanhas: string = "CAMPANHAS";
  combo: string = "COMBO";
  maisVendidos: string = "MAIS VENDIDOS";
  promocoes: string = "PROMOÇÕES";
  reposicoes: string = "REPOSIÇÕES";

  /* Carregamento */

  carregando: boolean = false;

  /* Carrinho */
  
  carrinho: ProdutoPromocional[] = [];

  carrinhoExibir: boolean = false;

  carrinhoValor: number = 0;

  /* Campanhas */

  existeCampanha: boolean = false;

  /* Cliente */

  cliente: Cliente = null;

  /* Cliente (Locais Entrega) */

  clienteLocaisEntrega: ClienteLocalEntrega[] = [];

  clienteLocalEntregaSelecionado: ClienteLocalEntrega = null;

  /* Configurações */

  configuracao: Configuracao = null;

  /* Configuração Banner */

  configuracaoBanner: ConfiguracaoBanner[] = [];

  conteudoBanners: ConfiguracaoBanner[] = [];
  conteudoCarousels: ConfiguracaoBanner[] = [];
  conteudoCategorias: ConfiguracaoBanner[] = [];
  conteudoTitulos: ConfiguracaoBanner[] = [];

  /* Dt. Previsão de Entrega */

  dataPrevisaoEntrega: { year: number, month: number, day: number } = null;

  /* Forma de Pagamento */

  formasPagamento: FormaPagamento[] = [];

  formaPagamentoErro: boolean = false;

  formaPagamentoSelecionada: FormaPagamento = null;

  /* Observação */

  observacao: string = "";

  /* Imagem */

  produtoCarregando: string = "assets/loader.gif";
  produtoSemImagem: string = "assets/produto_sem_imagem.png";

  /* Indicar */

  exibirIndicar: boolean = false;

  /* Mercado Pago */

  mp: any = null;

  bb: any = null;

  bricksController: any = null;

  mpCarregado: boolean = false;
  mpPagamento: MeiosPagamentoCartoesMercadoPagoRetorno = null;

  /* Pagamento */

  tabPagamentoSelecionada: string = "cartao";

  /* Pedido Vinculado */

  pedidoVinculado: string = "";

  /* Pedidos */

  nroPedido: number = 0;

  pedido: Pedido = null;

  /* Pedidos (Itens) */

  pedidoItens: PedidoItens[] = [];

  /* Prazo de Pagamento */

  prazosPagamento: PrazoPagamento[] = [];

  prazoPagamentoErro: boolean = false;

  prazoPagamentoSelecionado: PrazoPagamento = null;

  /* Prazo de Pagamento (Cliente) */

  prazosPagamentoCliente: PrazoPagamentoCliente[] = [];

  /* Prazo de Pagamento (Parcelas) */

  prazosPagamentoParcela: PrazoPagamento[] = [];

  prazoPagamentoParcelaErro: boolean = false;

  prazoPagamentoParcelaSelecionado: PrazoPagamento = null;

  /* Produtos */

  produtos: ProdutoPromocional[] = [];
  produtosOriginal: ProdutoPromocional[] = [];

  produtosAgrupados: ProdutoPromocionalAgrupado[] = [];

  produtosBuscaAtual: ProdutoPromocional[] = [];

  produtoSelecionado: ProdutoPromocional = null;

  /* Produtos (Busca (Grupo)) */

  produtoBuscarPorGrupo: boolean = false;

  /* Produtos (Busca (Medida)) */

  produtoBuscarPorAro: any[] = [];

  produtoBuscarPorAroSelecionado: string = "";

  produtoBuscarPorLargura: any[] = [];

  produtoBuscarPorLarguraSelecionado: string = "";

  produtoBuscarPorPerfil: any[] = [];

  produtoBuscarPorPerfilSelecionado: string = "";

  produtoBuscarPorMedidaEnviado: boolean = false;

  /* Produtos (Busca (Nome)) */

  produtoBuscarPorNome: string = "";

  produtoBuscarPorNomeEnviado: boolean = false;

  /* Produtos (Categorias) */

  produtosCategorias: ProdutoCategoria[] = [];

  produtoCategoriaSelecionada: string = "";

  produtoCategoriaSelecionadaQtde: number = 0;

  /* Produtos (Fotos) */

  produtoFotos: ProdutoFoto[] = [];

  produtoFotosSelecionado: ProdutoPromocional = null;

  /* Produtos (Grupos) */

  produtosGrupos0: ProdutoGrupo[] = [];
  produtosGrupos1: ProdutoGrupo[] = [];
  produtosGrupos2: ProdutoGrupo[] = [];
  produtosGrupos3: ProdutoGrupo[] = [];
  produtosGrupos4: ProdutoGrupo[] = [];
  produtosGrupos5: ProdutoGrupo[] = [];

  produtosGruposExpandido: boolean = true;

  /* Produtos (Grupos Nomes) */

  produtosGruposNomes: ProdutoGrupoNome[] = [];

  /* Produtos (Segmentos) */

  produtosSegmentos: ProdutoSegmento[] = [];

  /* Qtde */

  qtde: number = 1;

  /* Qtde (Disponibilidade) */

  qtdeDisponibilidade: number = 0;

  /* Produtos (Ficha Técnica) */

  produtosFichaTecnica: ProdutoFichaTecnica[] = [];

  /* Tipo Pedido */

  tiposPedido: TipoPedido[] = [];

  /* Storage */

  logomarca: string = localStorage.getItem("logomarca");
  seller_id: string = localStorage.getItem("seller_id");
  client_id_app: string = localStorage.getItem("client_id_app");
  guidclientenapis: string = localStorage.getItem("guidclientenapis");
  name: string = localStorage.getItem("name");
  enderecoCompleto: string = localStorage.getItem("endereco_completo");
  segment: string = localStorage.getItem("segment");
  client_document: string = localStorage.getItem("client_document");
  b2b_margem_consumidor: number = parseFloat(localStorage.getItem("b2b_margem_consumidor"));
  b2b_usa_como_orcamento: boolean = (localStorage.getItem("b2b_usa_como_orcamento") == "true");
  mercadopago_public_token: string = localStorage.getItem("mercadopago_public_token");
  b2b_cor_primaria: string = localStorage.getItem("b2b_cor_primaria");
  catalogo: boolean = (localStorage.getItem("catalogo") == "true");

  /* Variação */

  variacaoSelecionada: number = null;

  /* Verbos */

  verbosCompraOrcamento: string = "Compra";
  verbosComprandoOrcando: string = "Comprando";

  /* Childs */

  @ViewChild("aviso", { static: false }) aviso: ElementRef;
  @ViewChild("categoria", { static: false }) categoria: ElementRef;
  @ViewChild("titulo", { static: false }) titulo: ElementRef;

  @ViewChildren("produto_por_nome_geral") produto_por_nome_geral: QueryList<ElementRef>;
  @ViewChildren("#produto_por_nome_medida") produto_por_nome_medida: QueryList<ElementRef>;

  /* Modal */

  modalReferenciaPagamento: NgbModalRef = null;

  @ViewChild("notificacoes", { static: false }) notificacoes: ElementRef;
  @ViewChild("pagamento", { static: false }) pagamento: ElementRef;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private deviceDetectorService: DeviceDetectorService,
    private datePipe: DatePipe,
    private domSanitizer: DomSanitizer,
    @Inject("BASE_URL") baseUrl: string,
    private authService: AuthService,
    private configuracaoService: ConfiguracaoService,
    private clienteService: ClienteService,
    private formaPagamentoService: FormaPagamentoService,
    private pedidoService: PedidoService,
    private pedidoItensService: PedidoItensService,
    private prazoPagamentoClienteService: PrazoPagamentoClienteService,
    private prazoPagamentoService: PrazoPagamentoService,
    private produtoService: ProdutoService,
    private tipoPedidoService: TipoPedidoService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.carregarDados();

    if (this.deviceDetectorService.isMobile()) {
      this.produtosGruposExpandido = false;
    } else {
      this.produtosGruposExpandido = true;
    }

    /* Indicar */

    if (window.location.href.indexOf("atrialub") > -1) {
      this.exibirIndicar = true;
    }

    /* Mercado Pago */

    this.mp = new MercadoPago(this.mercadopago_public_token, {
      locale: "pt-BR"
    });

    this.bb = this.mp.bricks();
  }

  ngAfterViewInit() {
    //this.modalNotificacoes(this.notificacoes);

    this.focoProdutoPorNome();

    this.produto_por_nome_geral.changes.subscribe(() => {
      this.focoProdutoPorNome();
    });

    this.produto_por_nome_medida.changes.subscribe(() => {
      this.focoProdutoPorNome();
    });
  }

  /* Ações */

  abrirCarrinho() {
    if (this.validarAcesso()) {
      /* Carrinho */

      this.carrinhoExibir = true;

      /* Produtos */

      this.produtoSelecionado = null;

      this.moverParaTopo();
    }
  }

  abrirLinkShell() {
    window.open("https://www.integralubs.com.br/login/?perfil", "_blank");
  }

  adicionarCarrinho() {
    let adicionarProduto: boolean = this.adicionarProduto();

    if (adicionarProduto) {
      this.abrirCarrinho();
    }
  }

  adicionarProduto(): boolean {
    if (this.produtoSelecionado != null) {
      if (this.qtde == null || this.qtde <= 0 || this.qtde > 9999) {
        return false;
      } else if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N" && this.produtoSelecionado.estoque < this.qtde) {
        this.toastr.error("", "Qtde acima do estoque! Disponível: " + this.produtoSelecionado.estoque);

        return false;
      } else {
        if (this.produtoSelecionado.categoria == this.campanhas && this.produtoSelecionado.qtde_minima_atual < this.produtoSelecionado.qtde_minima) {
          this.toastr.error("", "Qtde Mínima de Itens inválida!");

          return false;
        } else {
          let produto: number = -1;

          if (this.produtoSelecionado.id_campanha != null) {
            /* Campanhas */

            produto = this.carrinho.findIndex(carrinho => carrinho.id_campanha == this.produtoSelecionado.id_campanha);
          } else {
            produto = this.carrinho.findIndex(carrinho => carrinho.id == this.produtoSelecionado.id);
          }

          if (produto != -1) {
            this.carrinho.splice(produto, 1);
          }

          this.produtoSelecionado.qtde = this.qtde;

          /* Totalizar */

          if (this.produtoSelecionado.grupo_estoque == this.combo) {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.valor_total_produtos * this.qtde;
          } else {
            if (this.produtoSelecionado.price_promotional > 0) {
              this.produtoSelecionado.valor_total = this.produtoSelecionado.price_promotional * this.qtde;
            } else {
              this.produtoSelecionado.valor_total = this.produtoSelecionado.price * this.qtde;
            }
          }

          if (this.configuracao != null && this.configuracao.b2b_modelo_tela == 1) {
            if (this.produtoSelecionado.id != 0 && this.produtoSelecionado.produtos_vinculados != null && this.produtoSelecionado.produtos_vinculados.length > 0) {
              /* Produtos */

              this.produtoSelecionado.id_variacao = this.variacaoSelecionada;
            }
          }

          this.carrinho.push(this.produtoSelecionado);

          this.totalizarCarrinho();

          return true;
        }
      }
    }

    return false;
  }

  continuarComprando() {
    let adicionarProduto: boolean = this.adicionarProduto();

    if (adicionarProduto) {
      this.continuarComprandoRetornar();
    }
  }

  continuarComprandoRetornar() {
    /* Carrinho */

    this.carrinhoExibir = false;

    /* Produtos */

    this.produtoSelecionado = null;

    this.moverParaTopo();
  }

  excluirProdutoAdicionado(indice: number) {
    this.carrinho.splice(indice, 1);

    this.totalizarCarrinho();
  }

  expandirProdutosGrupos() {
    this.produtosGruposExpandido = !this.produtosGruposExpandido;
  }

  focoProdutoPorNome() {
    if (this.configuracao != null && this.configuracao.b2b_busca_medida) {
      if (this.produto_por_nome_medida.length > 0) {
        setTimeout(() => {
          this.produto_por_nome_medida.first.nativeElement.focus();
        });
      }
    } else {
      if (this.produto_por_nome_geral.length > 0) {
        setTimeout(() => {
          this.produto_por_nome_geral.first.nativeElement.focus();
        });
      }
    }
  }

  indicar() {
    let client_document: string = this.client_document.split(".").join("").split("-").join("").split("/").join("");

    let url: string = "https://app-indicar.web.app/#b2b-michelin?cnpj=" + client_document;

    window.open(url, "_blank");
  }

  moverParaCategoria() {
    setTimeout(() => {
      this.categoria.nativeElement.scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    }, 800)
  }

  moverParaTopo() {
    setTimeout(() => {
      this.titulo.nativeElement.scrollIntoView({
        block: "end",
        behavior: "smooth"
      });
    }, 800)
  }

  reiniciar(selecionar_promocoes: boolean) {
    /* Carrinho */

    this.carrinhoExibir = false;

    /* Produtos */

    this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));

    this.produtoSelecionado = null;

    /* Produtos (Busca (Grupo)) */

    this.produtoBuscarPorGrupo = false;

    /* Produtos (Busca (Medida)) */

    this.produtoBuscarPorAroSelecionado = "";

    this.produtoBuscarPorLarguraSelecionado = "";

    this.produtoBuscarPorPerfilSelecionado = "";

    this.produtoBuscarPorMedidaEnviado = false;

    this.organizarMedidas();

    /* Produtos (Busca (Nome)) */

    this.produtoBuscarPorNome = "";

    this.produtoBuscarPorNomeEnviado = false;

    /* Produtos (Categorias) */

    if (selecionar_promocoes) {
      this.selecionarCategoria(true, this.promocoes);
    }

    this.moverParaTopo();
  }

  reiniciarProduto() {
    /* Produtos */

    this.produtoSelecionado = null;

    this.qtde = 1;

    /* Produtos (Ficha Técnica) */

    this.produtosFichaTecnica = [];

    /* Variação */

    this.variacaoSelecionada = null;
  }

  recuperarProduto(id?: number, id_campanha?: number) {
    if (this.produtoSelecionado != null) {
      let produtoCarrinho: ProdutoPromocional = null;

      if (id_campanha != null) {
        /* Campanhas */

        produtoCarrinho = this.carrinho.find(carrinho => carrinho.id_campanha == id_campanha);
      } else {
        /* Produtos */

        produtoCarrinho = this.carrinho.find(carrinho => carrinho.id == id);
      }

      if (produtoCarrinho != null) {
        this.qtde = produtoCarrinho.qtde;

        this.produtoSelecionado.price = produtoCarrinho.price;
        this.produtoSelecionado.price_promotional = produtoCarrinho.price_promotional;

        this.produtoSelecionado.valor_total_produtos = produtoCarrinho.valor_total_produtos;
        this.produtoSelecionado.valor_total = produtoCarrinho.valor_total;

        if (this.produtoSelecionado.grupo_estoque == this.combo) {
          if (this.produtoSelecionado.produtos_vinculados != null) {
            /* Produtos Vinculados */

            this.produtoSelecionado.produtos_vinculados.forEach((item) => {
              let produtoVinculado = produtoCarrinho.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == item.id);

              if (produtoVinculado != null) {
                item.qtde = produtoVinculado.qtde;
              } else {
                item.qtde = item.qtde_minima;
              }
            });

            this.validarQtdeMinimaCombo();
          }
        }
      } else {
        if (this.produtoSelecionado.produtos_vinculados != null) {
          /* Produtos Vinculados */

          this.produtoSelecionado.produtos_vinculados.forEach((item) => {
            item.qtde = item.qtde_minima;

            item.valor_total = item.price_promotional * item.qtde_minima;

            if (item.bonificacao) {
              item.bonificacao_selecionada = false;
            }
          });

          /* Produto Vinculado (Selecionado) */

          if (this.produtoSelecionado.bonificacao_selecionavel) {
            let produtoVinculado: ProdutoPromocional = this.produtoSelecionado.produtos_vinculados.find(produtosVinculados => produtosVinculados.bonificacao);

            if (produtoVinculado != null) {
              produtoVinculado.bonificacao_selecionada = true;
            }
          }
        }
      }
    }
  }

  selecionarProduto(id: number, id_campanha: number, carrinho: boolean) {
    if (this.validarAcesso()) {
      this.reiniciarProduto();

      if (carrinho) {
        this.reiniciar(false);
      }

      if (id_campanha != null) {
        /* Campanhas */

        this.produtoSelecionado = this.produtos.find(produto => produto.id_campanha == id_campanha);

        if (this.produtoSelecionado != null) {
          this.recuperarProduto(null, id_campanha);

          /* Bonificação */

          this.localizarProdutoBonificado();
          this.definirQtdeBonificacaoMultiplicavel(null);

          this.moverParaTopo();
        }
      } else {
        /* Produtos */

        this.produtoSelecionado = this.produtos.find(produto => produto.id == id);

        if (this.produtoSelecionado != null) {
          if (this.configuracao != null && this.configuracao.b2b_modelo_tela == 1) {
            /* Variação */

            let produtoCarrinho: ProdutoPromocional = this.carrinho.find(produto => produto.id == id);

            if (produtoCarrinho != null && produtoCarrinho.id_variacao != null) {
              this.variacaoSelecionada = produtoCarrinho.id_variacao;
            } else {
              if (this.produtoSelecionado.produtos_vinculados != null && this.produtoSelecionado.produtos_vinculados.length > 0) {
                this.variacaoSelecionada = this.produtoSelecionado.produtos_vinculados[0].id;
              }
            }
          }

          this.recuperarProduto(id, null);

          /* Produtos (Ficha Ténica) */

          this.produtoService.getFichaTecnicaByIdProduto(this.produtoSelecionado.id).subscribe(
            produtosFichaTecnica => this.produtosFichaTecnica = produtosFichaTecnica,
            error => { console.log("Erro: " + error) },
            () => {
              let precoPromocional: number = 0;

              if (this.produtoSelecionado.price_promotional == 0) {
                precoPromocional = this.produtoSelecionado.price;
              } else {
                precoPromocional = this.produtoSelecionado.price_promotional;
              }

              /* Produtos (Tabelas (Qtde)) */

              let produtoTabelaPromocaoQtde: ProdutoTabelaPromocaoQtde[] = [];

              this.produtoService.getProdutoTabelaPromocaoQtde(this.produtoSelecionado.id, precoPromocional).subscribe(
                produtosTabelaPromocaoQtde => produtoTabelaPromocaoQtde = produtosTabelaPromocaoQtde,
                error => { console.log("Erro: " + error) },
                () => {
                  if (produtoTabelaPromocaoQtde.length > 0) {
                    this.produtoSelecionado.tabela_promocao_qtde = produtoTabelaPromocaoQtde;
                  }

                  this.moverParaTopo();
                }
              );
            }
          );
        }
      }
    }
  }

  /* Avise-me */

  atualizarAviseMe() {
    if (this.produtoSelecionado != null) {
      let alterarProdutoDisponibilidade: boolean = false;

      let produto = new ProdutoDisponibilidade();

      produto.client_id_app = parseInt(this.client_id_app);
      produto.product_id = this.produtoSelecionado.id;
      produto.qtde = this.qtde;
      produto.flag_notificado = false;

      if (this.produtoSelecionado.disponibilidade) {
        this.produtoService.updateDisponibilidadeQtde(produto).subscribe(
          alterarProdutosDisponibilidade => alterarProdutoDisponibilidade = alterarProdutosDisponibilidade,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarProdutoDisponibilidade) {
              this.toastr.success("", "Avise-me atualizado com sucesso!");
            }
          }
        );
      } else {
        this.produtoService.addDisponibilidade(produto).subscribe(
          alterarProdutosDisponibilidade => alterarProdutoDisponibilidade = alterarProdutosDisponibilidade,
          error => { console.log("Erro: " + error) },
          () => {
            if (alterarProdutoDisponibilidade) {
              let produtoAlterado = this.produtos.find(produto => produto.id == this.produtoSelecionado.id);

              if (produtoAlterado != null) {
                produtoAlterado.disponibilidade = true;
              }

              produtoAlterado = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == this.produtoSelecionado.id);

              if (produtoAlterado != null) {
                produtoAlterado.disponibilidade = true;
              }

              this.qtdeDisponibilidade++;

              this.toastr.success("", "Avise-me salvo com sucesso!");
            }
          }
        );
      }
    }
  }

  removerAviseMe() {
    if (this.produtoSelecionado != null) {
      let excluirProdutoDisponibilidade: boolean = false;

      this.produtoService.deleteDisponibilidade(this.produtoSelecionado.id).subscribe(
        excluirProdutosDisponibilidade => excluirProdutoDisponibilidade = excluirProdutosDisponibilidade,
        error => { console.log("Erro: " + error) },
        () => {
          if (excluirProdutoDisponibilidade) {
            let produtoExcluido = this.produtos.find(produto => produto.id == this.produtoSelecionado.id);

            if (produtoExcluido != null) {
              produtoExcluido.disponibilidade = false;
            }

            produtoExcluido = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == this.produtoSelecionado.id);

            if (produtoExcluido != null) {
              produtoExcluido.disponibilidade = false;
            }

            this.qtdeDisponibilidade--;

            this.toastr.success("", "Avise-me removido com sucesso!");
          }
        }
      );
    }
  }

  /* Avisos */

  removerAvisos() {
    if (this.aviso != null) {
      this.aviso.nativeElement.style.display = "none";
    }
  }

  verificarAvisos() {
    if (this.configuracao != null && (this.configuracao.guidclientenapis.toUpperCase() == '5C430E97-CEA3-43CA-BFAE-DCC4F8F558CB' || this.configuracao.guidclientenapis.toUpperCase() == 'C767BADA-BF09-4A11-900B-ACD76FF0177C')) {
      this.exibirAviso = true;
    }
  }

  /* Bonificação */

  alterarBonificacao(id: string, id_campanha: string) {
    let idCampanha: number = parseInt(id_campanha);
    let idProduto: number = parseInt(id);

    let produto = this.produtos.find(produto => produto.id_campanha == idCampanha);

    if (produto != null) {
      if (produto.produtos_vinculados != null) {
        /* Produtos Vinculados */

        produto.produtos_vinculados.forEach((item) => {
          if (item.bonificacao) {
            if (item.id == idProduto) {
              item.bonificacao_selecionada = true;
            } else {
              item.bonificacao_selecionada = false;
            }
          }
        });
      }
    }
  }

  /* Compra */

  finalizarCompra() {
    this.toastr.success("", "Compra realizada com sucesso!");
    this.toastr.success("", "Compra enviada com sucesso!");

    this.reiniciarCompra();
    this.reiniciar(true);
  }

  reiniciarCompra() {
    /* Carrinho */

    this.carrinho = [];

    this.carrinhoValor = 0;

    /* Cliente (Locais Entrega) */

    this.clienteLocalEntregaSelecionado = null;

    /* Dt. Previsão de Entrega */

    this.dataPrevisaoEntrega = {
      year: null,
      month: null,
      day: null
    };

    /* Observação */

    this.observacao = "";

    /* Mercado Pago */

    this.mpPagamento = null;

    /* Pedido Vinculado */

    this.pedidoVinculado = "";

    /* Pedidos */

    this.nroPedido = 0;

    this.pedido = null;

    /* Pedidos (Itens) */

    this.pedidoItens = [];

    /* Forma de Pagamento */

    if (this.formasPagamento.length > 0) {
      this.formaPagamentoSelecionada = this.formasPagamento.find(formaPagamento => formaPagamento.padrao); /* PADRÃO */

      if (this.formaPagamentoSelecionada == null) {
        this.formaPagamentoSelecionada = this.formasPagamento[0];
      }
    }

    /* Prazo de Pagamento */

    this.validarPrazoPagamentoParcela();
    this.validarPrazoPagamento();
  }

  /* Dados */

  adicionarPedido(produtoPromocionalAgrupado: ProdutoPromocionalAgrupado) {
    let retorno = new Promise<void>((resolver) => {
      this.pedidoService.getProximoNumeroPedidoBySeller().subscribe(
        nroPedido => this.nroPedido = nroPedido,
        error => { console.log("Erro: " + error) },
        () => {
          if (this.nroPedido > 0) {
            this.prepararEnviarDados(produtoPromocionalAgrupado);

            /* Pedido */

            let adicionarPedido: boolean = false;

            this.pedidoService.add(this.pedido).subscribe(
              adicionarPedidos => adicionarPedido = adicionarPedidos,
              error => { console.log("Erro: " + error) },
              () => {
                if (adicionarPedido) {
                  /* Pedido Itens */

                  let adicionarPedidoItens: boolean = false;

                  this.pedidoItensService.add(this.pedidoItens).subscribe(
                    adicionarPedidosItens => adicionarPedidoItens = adicionarPedidosItens,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (adicionarPedidoItens) {
                        if (!this.b2b_usa_como_orcamento) {
                          /* Pedido (Enviar) */

                          let pedidoEnviar: PedidoBonificacaoRetorno = null;

                          this.pedidoService.enviar(this.nroPedido, 0).subscribe(
                            pedidosEnviar => pedidoEnviar = pedidosEnviar,
                            error => { console.log("Erro: " + error) },
                            () => {
                              return resolver();
                            }
                          );
                        } else {
                          return resolver();
                        }
                      }
                    }
                  );
                }
              }
            );
          } else {
            this.toastr.error("", "Houve um erro, contate o suporte...");

            return resolver();
          }
        }
      );
    });

    return retorno;
  }

  agruparCarrinho() {
    let retorno = new Promise<void>((resolver) => {
      /* Produtos */

      let produtoPromocionalTemporario: ProdutoPromocional[] = [];

      /* Variação */

      if (this.configuracao != null && this.configuracao.b2b_modelo_tela == 1) {
        let carrinhoTemporario: ProdutoPromocional[] = [];

        this.carrinho.forEach((item) => {
          if (item.id_variacao != null) {
            item.produtos_vinculados.forEach((produto_vinculado) => {
              if (produto_vinculado.id == item.id_variacao) {
                produto_vinculado.qtde = item.qtde;
                produto_vinculado.price = item.price;
                produto_vinculado.price_promotional = item.price_promotional;

                carrinhoTemporario.push(produto_vinculado);
              }
            });
          } else {
            carrinhoTemporario.push(item);
          }
        });

        this.carrinho = carrinhoTemporario;
      }

      this.carrinho.forEach((item) => {
        if (item.id != 0) {
          produtoPromocionalTemporario.push(item);
        }

        if (item.produtos_vinculados != null) {
          /* Produtos Vinculados */

          item.produtos_vinculados.forEach((subItem) => {
            if (subItem.qtde > 0) {
              if (item.preco_bonificacao == "B") {
                /* Bonificação */

                if (subItem.bonificacao) {
                  if (subItem.bonificacao_selecionada) {
                    if (item.bonificacao_multiplicavel) {
                      /* Bonificação (Multiplicável) */

                      let qtdeBonificacaoMultiplicavel: number = item.qtde_bonificacao_multiplicavel != null ? item.qtde_bonificacao_multiplicavel : 0;

                      if (item.bonificacao_selecionavel) {
                        /* Bonificação (Selecionável) */

                        subItem.qtde = subItem.qtde_minima * qtdeBonificacaoMultiplicavel;
                      } else {
                        subItem.qtde = qtdeBonificacaoMultiplicavel;
                      }

                      produtoPromocionalTemporario.push(subItem);
                    } else {
                      produtoPromocionalTemporario.push(subItem);
                    }
                  }
                } else {
                  if (!item.bonificacao_selecionavel && subItem.bonificacao_selecionada) {
                    let produtoVinculado: ProdutoPromocional = { ...subItem };

                    /* Produto Origem */

                    subItem.bonificacao_selecionada = false;
                    subItem.qtde = subItem.qtde * item.qtde;

                    produtoPromocionalTemporario.push(subItem);

                    /* Produto Bonificado */

                    produtoVinculado.bonificacao = true;

                    if (item.bonificacao_multiplicavel) {
                      produtoVinculado.qtde = item.qtde_bonificacao_multiplicavel;
                    } else {
                      produtoVinculado.qtde = 1;
                    }

                    produtoPromocionalTemporario.push(produtoVinculado);
                  } else {
                    subItem.qtde = subItem.qtde * item.qtde;

                    produtoPromocionalTemporario.push(subItem);
                  }
                }
              } else {
                subItem.qtde = subItem.qtde * item.qtde;

                produtoPromocionalTemporario.push(subItem);
              }
            }
          });
        }
      });

      /* Agrega Qtde */

      let produtoPromocional: ProdutoPromocional[] = [];

      produtoPromocionalTemporario.forEach((item) => {
        let produto = produtoPromocional.find(produtoPromocional => produtoPromocional.id == item.id && produtoPromocional.bonificacao == item.bonificacao && produtoPromocional.price_promotional == item.price_promotional && produtoPromocional.cod_lote == item.cod_lote);;

        if (produto != null) {
          produto.qtde = produto.qtde + item.qtde;
        } else {
          produtoPromocional.push(item);
        }
      });

      /* Produtos Agrupados */

      this.produtosAgrupados = [];

      produtoPromocional.forEach((item) => {
        if (this.produtosAgrupados.length == 0) {
          let produtoPromocionalAgrupado = new ProdutoPromocionalAgrupado();

          produtoPromocionalAgrupado.bonificacao = item.bonificacao;
          produtoPromocionalAgrupado.cod_lote = item.cod_lote;
          produtoPromocionalAgrupado.referencia_cliente = item.referencia_cliente;
          produtoPromocionalAgrupado.produtoPromocional = [];

          produtoPromocionalAgrupado.produtoPromocional.push(item);

          this.produtosAgrupados.push(produtoPromocionalAgrupado);
        } else {
          let novoAgrupamento: boolean = false;

          let produtoAdicionado: boolean = false;

          this.produtosAgrupados.forEach((subItem) => {
            if (!produtoAdicionado) {
              if (item.referencia_cliente != subItem.referencia_cliente || item.cod_lote != subItem.cod_lote) {
                novoAgrupamento = true;
              } else {
                if (item.bonificacao) {
                  /* Bonificação */

                  if (subItem.bonificacao) {
                    let produto = subItem.produtoPromocional.find(produtoPromocional => produtoPromocional.id == item.id);

                    if (produto == null) {
                      subItem.produtoPromocional.push(item);

                      novoAgrupamento = false;
                      produtoAdicionado = true;
                    } else {
                      novoAgrupamento = true;
                    }
                  }
                } else {
                  if (!subItem.bonificacao) {
                    let produto = subItem.produtoPromocional.find(produtoPromocional => produtoPromocional.id == item.id);

                    if (produto == null) {
                      subItem.produtoPromocional.push(item);

                      novoAgrupamento = false;
                      produtoAdicionado = true;
                    } else {
                      novoAgrupamento = true;
                    }
                  }
                }
              }
            }
          });

          if (novoAgrupamento || !produtoAdicionado) {
            let produtoPromocionalAgrupado = new ProdutoPromocionalAgrupado();

            produtoPromocionalAgrupado.bonificacao = item.bonificacao;
            produtoPromocionalAgrupado.cod_lote = item.cod_lote;
            produtoPromocionalAgrupado.referencia_cliente = item.referencia_cliente;
            produtoPromocionalAgrupado.produtoPromocional = [];

            produtoPromocionalAgrupado.produtoPromocional.push(item);

            this.produtosAgrupados.push(produtoPromocionalAgrupado);
          }
        }
      });

      return resolver();
    });

    return retorno;
  }

  agruparGrupos() {
    this.produtosGrupos0 = [];
    this.produtosGrupos1 = [];
    this.produtosGrupos2 = [];
    this.produtosGrupos3 = [];
    this.produtosGrupos4 = [];
    this.produtosGrupos5 = [];

    if (this.produtos.length > 0) {
      let produtoGrupo: ProdutoGrupo = null;

      let grupos0: ProdutoGrupo[] = [];
      let grupos1: ProdutoGrupo[] = [];
      let grupos2: ProdutoGrupo[] = [];
      let grupos3: ProdutoGrupo[] = [];
      let grupos4: ProdutoGrupo[] = [];
      let grupos5: ProdutoGrupo[] = [];

      this.produtos.forEach((item) => {
        /* Grupo */

        if (item.grupo_estoque != null && item.grupo_estoque != "") {
          produtoGrupo = grupos0.find(produtoGrupo => produtoGrupo.grupo == item.grupo_estoque);

          if (produtoGrupo == null) {
            let produtoGrupoNome = this.produtosGruposNomes != null && this.produtosGruposNomes.length > 0 ? this.produtosGruposNomes[0].nomegrupo : "";

            grupos0.push(new ProdutoGrupo(produtoGrupoNome, item.grupo_estoque, 0, false, true));
          }
        }

        /* SubGrupo 1 */

        if (item.subgrupo_estoque != null && item.subgrupo_estoque != "") {
          produtoGrupo = grupos1.find(produtoGrupo => produtoGrupo.grupo == item.subgrupo_estoque);

          if (produtoGrupo == null) {
            let produtoGrupoNome = this.produtosGruposNomes != null && this.produtosGruposNomes.length > 1 ? this.produtosGruposNomes[1].nomegrupo : "";

            grupos1.push(new ProdutoGrupo(produtoGrupoNome, item.subgrupo_estoque, 1, false, true));
          }
        }

        /* SubGrupo 2 */

        if (item.subgrupo_estoque2 != null && item.subgrupo_estoque2 != "") {
          produtoGrupo = grupos2.find(produtoGrupo => produtoGrupo.grupo == item.subgrupo_estoque2);

          if (produtoGrupo == null) {
            let produtoGrupoNome = this.produtosGruposNomes != null && this.produtosGruposNomes.length > 2 ? this.produtosGruposNomes[2].nomegrupo : "";

            grupos2.push(new ProdutoGrupo(produtoGrupoNome, item.subgrupo_estoque2, 2, false, true));
          }
        }

        /* SubGrupo 3 */

        if (item.subgrupo_estoque3 != null && item.subgrupo_estoque3 != "") {
          produtoGrupo = grupos3.find(produtoGrupo => produtoGrupo.grupo == item.subgrupo_estoque3);

          if (produtoGrupo == null) {
            let produtoGrupoNome = this.produtosGruposNomes != null && this.produtosGruposNomes.length > 3 ? this.produtosGruposNomes[3].nomegrupo : "";

            grupos3.push(new ProdutoGrupo(produtoGrupoNome, item.subgrupo_estoque3, 3, false, true));
          }
        }

        /* SubGrupo 4 */

        if (item.subgrupo_estoque4 != null && item.subgrupo_estoque4 != "") {
          produtoGrupo = grupos4.find(produtoGrupo => produtoGrupo.grupo == item.subgrupo_estoque4);

          if (produtoGrupo == null) {
            let produtoGrupoNome = this.produtosGruposNomes != null && this.produtosGruposNomes.length > 4 ? this.produtosGruposNomes[4].nomegrupo : "";

            grupos4.push(new ProdutoGrupo(produtoGrupoNome, item.subgrupo_estoque4, 4, false, true));
          }
        }

        /* SubGrupo 5 */

        if (item.subgrupo_estoque5 != null && item.subgrupo_estoque5 != "") {
          produtoGrupo = grupos5.find(produtoGrupo => produtoGrupo.grupo == item.subgrupo_estoque5);

          if (produtoGrupo == null) {
            let produtoGrupoNome = this.produtosGruposNomes != null && this.produtosGruposNomes.length > 5 ? this.produtosGruposNomes[5].nomegrupo : "";

            grupos5.push(new ProdutoGrupo(produtoGrupoNome, item.subgrupo_estoque5, 5, false, true));
          }
        }
      });

      /* Grupo */

      grupos0.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.grupo, b.grupo);
      });

      this.produtosGrupos0 = grupos0;

      /* SubGrupo 1 */

      grupos1.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.grupo, b.grupo);
      });

      this.produtosGrupos1 = grupos1;

      /* SubGrupo 2 */

      grupos2.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.grupo, b.grupo);
      });

      this.produtosGrupos2 = grupos2;

      /* SubGrupo 3 */

      grupos3.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.grupo, b.grupo);
      });

      this.produtosGrupos3 = grupos3;

      /* SubGrupo 4 */

      grupos4.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.grupo, b.grupo);
      });

      this.produtosGrupos4 = grupos4;

      /* SubGrupo 5 */

      grupos5.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.grupo, b.grupo);
      });

      this.produtosGrupos5 = grupos5;
    }
  }

  buscarImagens() {
    let idsImagens: number[] = [];

    let produto: ProdutoPromocional = null;

    this.produtos.forEach((item) => {
      if (!item.buscou_imagem) {
        item.buscou_imagem = true;

        if (item.id == 0) {
          /* Campanhas */

          item.produtos_vinculados.forEach((subItem) => {
            if (!subItem.buscou_imagem) {
              idsImagens.push(subItem.id);

              subItem.buscou_imagem = true;
            }
          });

          produto = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id_campanha == item.id);
        } else {
          idsImagens.push(item.id);

          produto = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == item.id);
        }

        if (produto != null) {
          produto.buscou_imagem = true;
        }
      }
    });

    if (idsImagens.length > 0) {
      let grupoIdsImagens = [];

      let qtdeIds: number = 100;

      grupoIdsImagens = idsImagens.map((objeto, index) => {
        return index % qtdeIds == 0 ? idsImagens.slice(index, index + qtdeIds) : null;
      }).filter(produto => produto != null);

      grupoIdsImagens.forEach((item) => {
        let produtoFoto: ProdutoFoto[] = [];
        let produtoVinculado: ProdutoPromocional = null;

        this.produtoService.getProdutoFotosByIds(item).subscribe(
          produtosFoto => produtoFoto = produtosFoto,
          error => { console.log("Erro: " + error) },
          () => {
            if (produtoFoto.length > 0) {
              produtoFoto.forEach((subItem) => {
                /* Produtos */

                produto = this.produtos.find(produto => produto.id == subItem.id_produto);

                if (produto != null) {
                  produto.buscou_imagem = true;
                  produto.imagem = "data:image/png;base64," + subItem.imagem;
                }

                /* Campanhas */

                this.produtos.filter(produto => produto.id == 0).forEach((subSubItem) => {
                  produtoVinculado = subSubItem.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == subItem.id_produto);

                  if (produtoVinculado != null) {
                    produtoVinculado.buscou_imagem = true;
                    produtoVinculado.imagem = "data:image/png;base64," + subItem.imagem;
                  }
                });

                /* Produto (Original) */

                produto = this.produtosOriginal.find(produtoOriginal => produtoOriginal.id == subItem.id_produto);

                if (produto != null) {
                  produto.buscou_imagem = true;
                  produto.imagem = "data:image/png;base64," + subItem.imagem;
                }

                /* Campanhas */

                this.produtosOriginal.filter(produtoOriginal => produtoOriginal.id == 0).forEach((subSubItem) => {
                  produtoVinculado = subSubItem.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == subItem.id_produto);

                  if (produtoVinculado != null) {
                    produtoVinculado.buscou_imagem = true;
                    produtoVinculado.imagem = "data:image/png;base64," + subItem.imagem;
                  }
                });
              });
            }
          }
        );
      });
    }
  }

  carregarDados() {
    this.carregando = true;

    /* Configurações */

    this.configuracaoService.getByGuidClienteNapis().subscribe(
      configuracoes => this.configuracao = configuracoes,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.b2b_usa_como_orcamento) {
          this.verbosCompraOrcamento = "Orçamento";
          this.verbosComprandoOrcando = "Orçando";
        }

        if (this.guidclientenapis.toUpperCase() == "C767BADA-BF09-4A11-900B-ACD76FF0177C") {
          /* PetrovilaTRR */

          this.promocoes = "PRODUTOS";
        }

        /* Configuração Banner */

        this.configuracaoService.getConfiguracaoBannerByGuidClienteNapis().subscribe(
          configuracoesBanners => this.configuracaoBanner = configuracoesBanners,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.configuracaoBanner.length > 0) {
              this.conteudoBanners = this.configuracaoBanner.filter(configuracaoBanner => configuracaoBanner.local == "Banner");
              this.conteudoCarousels = this.configuracaoBanner.filter(configuracaoBanner => configuracaoBanner.local == "Principal");
              this.conteudoCategorias = this.configuracaoBanner.filter(configuracaoBanner => configuracaoBanner.local == "Categorias");
              this.conteudoTitulos = this.configuracaoBanner.filter(configuracaoBanner => configuracaoBanner.local == "Título Categorias");
            }
          }
        );

        /* Forma de Pagamento */

        this.formaPagamentoService.getByGuidClienteNapis().subscribe(
          formasPagamento => this.formasPagamento = formasPagamento,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.formasPagamento.length > 0) {
              this.formaPagamentoSelecionada = this.formasPagamento.find(formaPagamento => formaPagamento.padrao); /* PADRÃO */

              if (this.formaPagamentoSelecionada == null) {
                this.formaPagamentoSelecionada = this.formasPagamento[0];
              }
            }

            /* Cliente */

            this.clienteService.getById(parseInt(this.client_id_app)).subscribe(
              cliente => this.cliente = cliente,
              error => { console.log("Erro: " + error) },
              () => {
                if (this.cliente != null) {
                  /* Cliente (Locais Entrega) */

                  this.clienteService.getClienteLocalEntregaByCliente(this.cliente.id).subscribe(
                    clienteLocalEntrega => this.clienteLocaisEntrega = clienteLocalEntrega,
                    error => { console.log("Erro: " + error) },
                    () => {

                    }
                  );

                  /* Prazo de Pagamento */

                  this.prazoPagamentoService.getByGuidClienteNapis().subscribe(
                    prazosPagamento => this.prazosPagamento = prazosPagamento,
                    error => { console.log("Erro: " + error) },
                    () => {
                      if (this.cliente.status_financeiro != null && this.cliente.status_financeiro != "") {
                        this.prazosPagamento = this.prazosPagamento.filter(prazoPagamento => prazoPagamento.permitir_inadimplente);
                      }

                      this.prazosPagamentoParcela = JSON.parse(JSON.stringify(this.prazosPagamento));

                      if (this.prazosPagamento.length > 0) {
                        /* Prazo de Pagamento (Cliente) */

                        this.prazoPagamentoClienteService.getByClientIdApp(this.cliente.client_id_app).subscribe(
                          prazosPagamentoClientes => this.prazosPagamentoCliente = prazosPagamentoClientes,
                          error => { console.log("Erro: " + error) },
                          () => {
                            if (this.prazosPagamentoCliente.length > 0) {
                              let prazosPagamentoSelecionados: PrazoPagamento[] = [];

                              this.prazosPagamentoCliente.forEach((item) => {
                                let prazoPagamentoSelecionado: PrazoPagamento = this.prazosPagamento.find(prazoPagamento => prazoPagamento.idprazopagamento == item.id_prazo);

                                if (prazoPagamentoSelecionado != null) {
                                  prazosPagamentoSelecionados.push(prazoPagamentoSelecionado);
                                }
                              });

                              this.prazosPagamento = prazosPagamentoSelecionados;
                            }

                            this.validarPrazoPagamentoParcela();
                            this.validarPrazoPagamento();
                            this.verificarAvisos();
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        );

        /* Produtos */

        this.produtoService.getProdutoPromocionalByGuidClienteNapis().subscribe(
          produtos => this.produtos = produtos,
          error => { console.log("Erro: " + error) },
          () => {
            if (this.produtos.length > 0) {
              this.produtos.forEach((item) => {
                item.id_order_item = 0;
                item.bonificacao_selecionada = false;
                item.qtde_minima_atual = 0;

                /* Qtde Mínima (Existente) */

                if (item.produtos_vinculados != null) {
                  if (item.grupo_estoque == this.combo) {
                    let existeQtdeMinima: number = item.produtos_vinculados.filter(produto_vinculado => produto_vinculado.qtde_minima > 0).length;

                    if (existeQtdeMinima > 0) {
                      item.qtde_minima_existente = true;
                    } else {
                      item.qtde_minima_existente = false;
                    }
                  } else {
                    item.qtde_minima_existente = false;
                  }
                } else {
                  item.qtde_minima_existente = false;
                }

                item.qtde_bonificacao_multiplicavel = 0;
                item.filtrado = false;

                /* Imagem */

                if (item.imagem != null && item.imagem != "") {
                  item.imagem = "data:image/png;base64," + item.imagem;

                  item.buscou_imagem = true;
                } else {
                  item.imagem = this.produtoSemImagem;

                  item.buscou_imagem = false;
                }

                if (item.produtos_vinculados != null) {
                  /* Produtos Vinculados */

                  this.existeCampanha = true;

                  item.produtos_vinculados.forEach((subItem) => {
                    subItem.id_order_item = 0;
                    subItem.bonificacao_selecionada = false;
                    subItem.qtde_minima_atual = 0;
                    subItem.qtde_bonificacao_multiplicavel = 0;

                    /* Imagem */

                    subItem.buscou_imagem = false;
                    subItem.imagem = this.produtoSemImagem;

                    /* Tabela Promoção */

                    subItem.price_promotional_percentual = 0;
                    subItem.price_promotional_original = 0;

                    /* Fator Financeiro */

                    subItem.price_original = subItem.price_promotional;
                  });

                  /* Qtde Mínima */

                  if (item.grupo_estoque == this.combo) {
                    let qtdeMinimaAtual: number = item.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.qtde_minima), 0);

                    item.qtde_minima_atual = qtdeMinimaAtual;
                  } else {
                    item.qtde_minima_atual = 0;
                  }

                  /* Produto Vinculado (Selecionado) */

                  if (item.bonificacao_selecionavel) {
                    let produtoVinculado: ProdutoPromocional = item.produtos_vinculados.find(produtosVinculados => produtosVinculados.bonificacao);

                    if (produtoVinculado != null) {
                      produtoVinculado.bonificacao_selecionada = true;
                    }
                  }
                }

                /* Disponibilidade */

                if (item.disponibilidade) {
                  this.qtdeDisponibilidade++;
                }

                /* Promoção */

                item.tabela_promocao_qtde = [];

                if (item.grupo_estoque == this.combo) {
                  item.price_promotional_percentual = 0;

                  item.valor_total = item.price_promotional;
                  item.valor_total_produtos = item.price_promotional;

                  /* Fator Financeiro */

                  item.price_original = item.price_promotional;
                } else {
                  if (item.price_promotional > 0) {
                    let porcentagemPromocional: number = 0;

                    porcentagemPromocional = (1 - (item.price / item.price_promotional)) * 100;

                    item.price_promotional_percentual = porcentagemPromocional;

                    item.valor_total = item.price_promotional;

                    /* Fator Financeiro */

                    item.price_original = item.price_promotional;
                  } else {
                    item.price_promotional_percentual = 0;

                    item.valor_total = item.price;

                    /* Fator Financeiro */

                    item.price_original = item.price;
                  }
                }

                item.price_promotional_original = item.price_promotional;
              });

              const ordernarProdutos = this.ordernarProdutos();

              ordernarProdutos.then(() => {
                this.produtosOriginal = JSON.parse(JSON.stringify(this.produtos));

                this.produtosBuscaAtual = JSON.parse(JSON.stringify(this.produtos));

                this.organizarMedidas();
              });

              /* Produtos (Categorias) */

              this.produtoService.getCategoriaByGuidClienteNapis().subscribe(
                produtosCategorias => this.produtosCategorias = produtosCategorias,
                error => { console.log("Erro: " + error) },
                () => {
                  this.organizarCategorias();
                }
              );
            }
          }
        );

        /* Produtos (Grupos Nomes) */

        this.produtoService.getProdutoGrupoNomeByGuidClienteNapis().subscribe(
          produtosGruposNomes => this.produtosGruposNomes = produtosGruposNomes,
          error => { console.log("Erro: " + error) },
          () => {

          }
        );

        /* Produtos (Segmentos) */

        this.produtoService.getProdutoSegmentoByGuidClienteNapis().subscribe(
          produtosSegmentos => this.produtosSegmentos = produtosSegmentos,
          error => { console.log("Erro: " + error) },
          () => {

          }
        );

        /* Tipo Pedido */

        this.tipoPedidoService.getByGuidClienteNapis().subscribe(
          tiposPedido => this.tiposPedido = tiposPedido,
          error => { console.log("Erro: " + error) },
          () => {

          }
        );
      }
    );
  }

  finalizarCarrinho() {
    const agruparCarrinho = this.agruparCarrinho();

    agruparCarrinho.then(() => {
      if (this.produtosAgrupados.length > 0) {
        if (this.produtosAgrupados.length < 10) {
          /* 0 */

          const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[0]);

          adicionarPedido.then(() => {
            if (this.produtosAgrupados[1] != null) {
              /* 1 */

              const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[1]);

              adicionarPedido.then(() => {
                if (this.produtosAgrupados[2] != null) {
                  /* 2 */

                  const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[2]);

                  adicionarPedido.then(() => {
                    if (this.produtosAgrupados[3] != null) {
                      /* 3 */

                      const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[3]);

                      adicionarPedido.then(() => {
                        if (this.produtosAgrupados[4] != null) {
                          /* 4 */

                          const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[4]);

                          adicionarPedido.then(() => {
                            if (this.produtosAgrupados[5] != null) {
                              /* 5 */

                              const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[5]);

                              adicionarPedido.then(() => {
                                if (this.produtosAgrupados[6] != null) {
                                  /* 6 */

                                  const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[6]);

                                  adicionarPedido.then(() => {
                                    if (this.produtosAgrupados[7] != null) {
                                      /* 7 */

                                      const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[7]);

                                      adicionarPedido.then(() => {
                                        if (this.produtosAgrupados[8] != null) {
                                          /* 8 */

                                          const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[8]);

                                          adicionarPedido.then(() => {
                                            if (this.produtosAgrupados[9] != null) {
                                              /* 9 */

                                              const adicionarPedido = this.adicionarPedido(this.produtosAgrupados[9]);

                                              adicionarPedido.then(() => {
                                                this.finalizarCompra();
                                              });
                                            } else {
                                              this.finalizarCompra();
                                            }
                                          });
                                        } else {
                                          this.finalizarCompra();
                                        }
                                      });
                                    } else {
                                      this.finalizarCompra();
                                    }
                                  });
                                } else {
                                  this.finalizarCompra();
                                }
                              });
                            } else {
                              this.finalizarCompra();
                            }
                          });
                        } else {
                          this.finalizarCompra();
                        }
                      });
                    } else {
                      this.finalizarCompra();
                    }
                  });
                } else {
                  this.finalizarCompra();
                }
              });
            } else {
              this.finalizarCompra();
            }
          });
        } else {
          this.toastr.error("", "Houve um erro, contate o suporte...");
        }
      }
    });
  }

  ordernarProdutos() {
    let retorno = new Promise<void>((resolver) => {
      /* Com Estoque */

      let produtosComEstoque: ProdutoPromocional[] = this.produtos.filter(produto => produto.estoque > 0);

      produtosComEstoque.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.name, b.name);
      });

      /* Sem Estoque */

      let produtosSemEstoque: ProdutoPromocional[] = this.produtos.filter(produto => produto.estoque <= 0);

      produtosSemEstoque.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.name, b.name);
      });

      this.produtos = produtosComEstoque;

      this.produtos.push.apply(this.produtos, produtosSemEstoque);

      return resolver();
    });

    return retorno;
  }

  organizarCategorias() {
    if (this.produtosCategorias != null) {
      let ordem: number = 0;
      let ordemFinal: number = 0;

      this.produtosCategorias.forEach((item) => {
        if (item.categoria == "OUTROS") {
          ordemFinal = this.produtosCategorias.length - 1;
        } else {
          ordemFinal = ordem++;
        }

        item.ordem = ordemFinal;
      });

      this.produtosCategorias.sort((a, b) => {
        const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

        return compare(a.ordem, b.ordem);
      });

      this.selecionarCategoria(false, this.promocoes);

      this.carregando = false;
    }
  }

  organizarMedidas() {
    let produtosOriginais = JSON.parse(JSON.stringify(this.produtosOriginal));

    /* Largura */

    if (this.produtoBuscarPorLarguraSelecionado == "") {
      this.produtoBuscarPorLargura = [];

      let largurasAgrupadas = produtosOriginais.reduce((obj, item) => {
        obj[item.subgrupo_estoque4] = obj[item.subgrupo_estoque4] || [];
        obj[item.subgrupo_estoque4].push(item);

        return obj;
      }, {});

      Object.keys(largurasAgrupadas).map((key) => {
        if (key != null && key != "") {
          this.produtoBuscarPorLargura.push({
            texto: key,
            valor: key
          });
        }
      });
    }

    /* Perfil */

    if (this.produtoBuscarPorLarguraSelecionado == "") {
      this.produtoBuscarPorPerfil = [];

      this.produtoBuscarPorPerfilSelecionado = "";
    } else if (this.produtoBuscarPorPerfilSelecionado == "") {
      produtosOriginais = produtosOriginais.filter(produtoOriginal => produtoOriginal.subgrupo_estoque4 == this.produtoBuscarPorLarguraSelecionado);

      let perfisAgrupados = produtosOriginais.reduce((obj, item) => {
        obj[item.subgrupo_estoque3] = obj[item.subgrupo_estoque3] || [];
        obj[item.subgrupo_estoque3].push(item);

        return obj;
      }, {});

      Object.keys(perfisAgrupados).map((key) => {
        if (key != null && key != "") {
          this.produtoBuscarPorPerfil.push({
            texto: key,
            valor: key
          });
        }
      });
    }

    /* Aro */

    if (this.produtoBuscarPorLarguraSelecionado == "" && this.produtoBuscarPorPerfilSelecionado == "") {
      this.produtoBuscarPorAro = [];

      this.produtoBuscarPorAroSelecionado = "";
    } else if (this.produtoBuscarPorAroSelecionado == "") {
      produtosOriginais = produtosOriginais.filter(produtoOriginal => produtoOriginal.subgrupo_estoque4 == this.produtoBuscarPorLarguraSelecionado && produtoOriginal.subgrupo_estoque3 == this.produtoBuscarPorPerfilSelecionado);

      let arosAgrupados = produtosOriginais.reduce((obj, item) => {
        obj[item.subgrupo_estoque2] = obj[item.subgrupo_estoque2] || [];
        obj[item.subgrupo_estoque2].push(item);

        return obj;
      }, {});

      Object.keys(arosAgrupados).map((key) => {
        if (key != null && key != "") {
          this.produtoBuscarPorAro.push({
            texto: key,
            valor: key
          });
        }
      });
    }
  }

  prepararEnviarDados(produtoPromocionalAgrupado: ProdutoPromocionalAgrupado) {
    /* Pedido */

    let pedido = new Pedido();

    pedido.client_id = parseInt(this.client_id_app);
    pedido.is_billed = true;
    pedido.is_active = true;
    pedido.sale_channel_id = 5;
    pedido.is_quotation = false;
    pedido.seller_id = parseInt(this.seller_id);
    pedido.referencia_do_pedido_no_cliente = this.pedidoVinculado != "" ? this.pedidoVinculado : null;
    pedido.observacao_interna = this.observacao;
    pedido.observacao_nf = null;
    pedido.id_prazo_pagamento = this.prazoPagamentoSelecionado.idprazopagamento;
    pedido.order_id_app = this.nroPedido; /* Nro Pedido */
    pedido.cd_transportadora = null;
    pedido.status_aprovacao_master = this.observacao != "" ? "P" : null;

    if (produtoPromocionalAgrupado.bonificacao) {
      /* Bonificação */

      pedido.cod_tipopedvenda = this.tiposPedido.find(tipoPedido => tipoPedido.padrao_bonificacao == "S").cod_tipopedvenda.toString();
    } else {
      let tipoPedidoVenda: TipoPedido = this.tiposPedido.find(tipoPedido => tipoPedido.flag_considerar_pedido_como_venda);

      if (tipoPedidoVenda != null) {
        pedido.cod_tipopedvenda = tipoPedidoVenda.cod_tipopedvenda.toString();
      } else {
        pedido.cod_tipopedvenda = null;
      }
    }

    pedido.id_tipo_frete = 4;
    pedido.master_seller_id = null;
    pedido.order_id_origem_bonificacao = 0;
    pedido.vlr_bonificacao = 0;
    pedido.qtd_itens_ativos = produtoPromocionalAgrupado.produtoPromocional.length;

    if (produtoPromocionalAgrupado.bonificacao) {
      pedido.origem_pedido = "B2B - BONIFICACAO";
    } else {
      pedido.origem_pedido = "B2B - PEDIDO";
    }

    pedido.guidclientenapis = this.guidclientenapis;
    pedido.mp_pagamento = this.mpPagamento;

    /* Dt. Previsão de Entrega */

    if (this.dataPrevisaoEntrega != null && this.dataPrevisaoEntrega.year != null && this.dataPrevisaoEntrega.month != null && this.dataPrevisaoEntrega.day != null) {
      let dataPrevisaoEntrega = new Date(this.dataPrevisaoEntrega.year, this.dataPrevisaoEntrega.month - 1, this.dataPrevisaoEntrega.day, 0, 0, 0);

      pedido.dt_previsao_entrega = new Date(this.datePipe.transform(dataPrevisaoEntrega, "yyyy/MM/dd HH:mm:ss", "GMT-6"));
    } else {
      pedido.dt_previsao_entrega = null;
    }

    /* Local de Entrega */

    pedido.id_local_entrega = this.clienteLocalEntregaSelecionado != null ? this.clienteLocalEntregaSelecionado.id : null;

    this.pedido = pedido;

    /* Pedido Itens */

    let pedidoItens: PedidoItens[] = [];

    produtoPromocionalAgrupado.produtoPromocional.forEach((item) => {
      let pedidoItem = new PedidoItens();

      pedidoItem.id = item.id_order_item;
      pedidoItem.order_id = this.nroPedido; /* Nro Pedido */
      pedidoItem.product_id = item.id;
      pedidoItem.quantity = item.qtde;

      if (item.bonificacao) {
        /* Bonificação */

        pedidoItem.unit_price = item.price_promotional;
      } else {
        pedidoItem.unit_price = item.price_promotional == 0 ? item.price : item.price_promotional;
      }

      pedidoItem.unit_id = 1;
      pedidoItem.is_active = item.is_active;
      pedidoItem.seller_id = parseInt(this.seller_id);
      pedidoItem.percentual_desconto = 0;
      pedidoItem.preco_tabela = pedidoItem.unit_price;
      pedidoItem.descritivo_desconto = "Pedido Ecommerce B2B";
      pedidoItem.preco_reposicao = 0;
      pedidoItem.id_kit_combo = item.id_campanha != null ? String(item.id_campanha) : null;

      pedidoItens.push(pedidoItem);
    });

    this.pedidoItens = pedidoItens;
  }

  /* Forma de Pagamento */

  alterarFormaPagamento(id_forma_pagamento: string) {
    let idSelecionado = parseInt(id_forma_pagamento);

    this.formaPagamentoSelecionada = this.formasPagamento.find(formaPagamento => formaPagamento.id == idSelecionado);

    /* Prazo de Pagamento */

    this.prazoPagamentoErro = false;

    this.prazoPagamentoSelecionado = null;

    /* Prazo de Pagamento (Parcelas) */

    this.prazoPagamentoParcelaErro = false;

    this.prazoPagamentoParcelaSelecionado = null;

    this.validarPrazoPagamentoParcela();
    this.validarPrazoPagamento();
  }

  /* Cliente (Locais Entrega) */

  selecionarLocalEntrega(id: string) {
    this.clienteLocalEntregaSelecionado = this.clienteLocaisEntrega.find(clienteLocalEntrega => clienteLocalEntrega.id == parseInt(id));
  }

  /* Mercado Pago */

  async carregarFormularioCartao() {
    this.mpCarregado = false;
    this.mpPagamento = null;

    const configuracoes = {
      initialization: {
        amount: this.carrinhoValor
      },
      customization: {
        visual: {
          style: {
            theme: "bootstrap"
          }
        }
      },
      callbacks: {
        onReady: () => {
          this.mpCarregado = true;
        },
        onSubmit: (cardFormData) => {
          return new Promise<void>((resolve, reject) => {
            fetch(this.baseUrl + "api/MeiosPagamentoCartoesMercadoPago/ProcessarPagamento?guidclientenapis=" + this.guidclientenapis, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(cardFormData)
            }).then((resposta) => resposta.json()).then((retorno) => {
              this.mpPagamento = retorno as MeiosPagamentoCartoesMercadoPagoRetorno;

              switch (this.mpPagamento.status) {
                case "approved":
                  this.toastr.success("", "Pagamento aprovado...");

                  this.modalReferenciaPagamento.close();

                  this.finalizarCarrinho();

                  break;
                case "authorized":
                  this.toastr.success("", "Pagamento autorizado...");

                  this.modalReferenciaPagamento.close();

                  this.finalizarCarrinho();

                  break;
                case "cancelled":
                  this.mpPagamento = null;

                  this.toastr.error("", "Pagamento cancelado...");

                  break;
                case "charged_back":
                  this.mpPagamento = null;

                  this.toastr.error("", "Pagamento estornado...");

                  break;
                case "in_mediation":
                case "rejected":
                  this.mpPagamento = null;

                  switch (this.mpPagamento.status_detail) {
                    case "cc_rejected_bad_filled_date":
                      this.toastr.error("Data de vencimento inválida...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_bad_filled_other":
                      this.toastr.error("Informações incorretas...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_bad_filled_security_code":
                      this.toastr.error("Código de segurança inválido...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_blacklist":
                      this.toastr.error("Blacklist...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_call_for_authorize":
                      this.toastr.error("Necessário autorização prévia...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_card_disabled":
                      this.toastr.error("Cartão inativo...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_duplicated_payment":
                      this.toastr.error("Pagamento duplicado...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_high_risk":
                      this.toastr.error("Fraud Prevention...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_insufficient_amount":
                      this.toastr.error("Valor insuficiente...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_invalid_installments":
                      this.toastr.error("Número de parcelas inválido...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_max_attempts":
                      this.toastr.error("Quantidade de tentivas excedidas...", "Pagamento rejeitado:");

                      break;
                    case "cc_rejected_other_reason":
                      this.toastr.error("Erro genérico...", "Pagamento rejeitado:");

                      break;
                    default:

                  }

                  break;
                case "in_process":
                case "pending":
                  switch (this.mpPagamento.status_detail) {
                    case "pending_contingency":
                      this.toastr.warning("Em Contigência...", "Pagamento em processamento:");

                      break;
                    case "cc_rejected_other_reason":
                      this.toastr.warning("Em Análise...", "Pagamento em processamento:");

                      break;
                    default:

                  }

                  this.modalReferenciaPagamento.close();

                  this.finalizarCarrinho();

                  break;
                case "refunded":
                  this.mpPagamento = null;

                  this.toastr.error("", "Pagamento devolvido...");

                  break;
                default:

              }

              resolve();
            }).catch(() => {
              this.modalReferenciaPagamento.close();

              this.toastr.error("", "Houve um erro, contate o suporte...");

              reject();
            })
          });
        },
        onError: () => {

        }
      }
    };

    const bricks = await this.mp.bricks();

    this.bricksController = await bricks.create("cardPayment", "cartao_container", configuracoes);
  }

  /* Modal */

  modalFinalizar(content: any) {
    if (this.formaPagamentoSelecionada == null) {
      this.formaPagamentoErro = true;

      this.toastr.error("", "Forma de Pagamento em branco!");
    } else {
      this.formaPagamentoErro = false;
    }

    if (this.configuracao != null && this.configuracao.b2b_seleciona_parcelas) {
      if (this.prazoPagamentoParcelaSelecionado == null) {
        this.prazoPagamentoParcelaErro = true;

        this.toastr.error("", "Parcelas em branco!");
      } else {
        this.prazoPagamentoParcelaErro = false;
      }
    } else {
      this.prazoPagamentoParcelaErro = false;
    }

    if (this.prazoPagamentoSelecionado == null) {
      this.prazoPagamentoErro = true;

      this.toastr.error("", "Prazo de Pagamento em branco!");
    } else {
      this.prazoPagamentoErro = false;
    }

    if (!this.formaPagamentoErro && !this.prazoPagamentoParcelaErro && !this.prazoPagamentoErro) {
      this.modalService.open(content, {
        ariaLabelledBy: "titulo-finalizar",
        size: "xl",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Confirmar") {
          if (this.prazoPagamentoSelecionado != null) {
            /* Tipo Pedido */

            let tipoPedidoBonificacao: TipoPedido = this.tiposPedido.find(tipoPedido => tipoPedido.padrao_bonificacao == "S");

            if (tipoPedidoBonificacao == null) {
              this.toastr.error("", "Nenhum tipo de pedido como bonificação...");

              return;
            }

            if (this.formaPagamentoSelecionada.plataform != null) {
              if (!this.b2b_usa_como_orcamento) {
                this.modalPagamento(this.pagamento);
              } else {
                this.toastr.error("", "Operação inválida para orçamento...");
              }
            } else {
              this.finalizarCarrinho();
            }
          } else {
            this.modalFinalizar(content);
          }
        }
      }, () => {

      });
    }
  }

  modalFotos(content: any, id_produto: number) {
    this.produtoFotosSelecionado = null;

    /* Produto (Fotos) */

    this.produtoService.getProdutoFotosByIdProduto(id_produto).subscribe(
      produtoFoto => this.produtoFotos = produtoFoto,
      error => { console.log("Erro: " + error) },
      () => {
        if (this.produtoFotos.length > 0) {
          this.produtoFotos.forEach((item) => {
            item.imagem = "data:image/png;base64," + item.imagem;
          });

          this.produtoFotosSelecionado = this.produtos.find(produto => produto.id == id_produto);

          this.modalService.open(content, {
            ariaLabelledBy: "titulo-fotos",
            size: "lg",
            backdrop: "static"
          });
        } else {
          this.toastr.error("", "Nenhuma foto encontrada!");
        }
      }
    );
  }

  modalNotificacoes(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: "titulo-notificacoes",
      size: "xl",
      backdrop: "static"
    }).result.then((acao) => {
      if (acao == "Continuar") {

      }
    }, () => {

    });
  }

  modalPagamento(content: any) {
    this.carregarFormularioCartao();

    this.modalReferenciaPagamento = this.modalService.open(content, {
      ariaLabelledBy: "titulo-pagamento",
      size: "lg",
      backdrop: "static"
    });

    this.modalReferenciaPagamento.result.then(() => {
      this.bricksController.unmount();
    }, () => {
      this.bricksController.unmount();
    });
  }

  modalSimular(content: any) {
    if (this.produtoSelecionado != null) {
      if (this.produtoSelecionado.price == 0 && this.produtoSelecionado.price_promotional == 0) {
        this.produtoSelecionado.percentual_margem = 0;
      } else {
        this.b2b_margem_consumidor = parseFloat(localStorage.getItem("b2b_margem_consumidor"));

        if (this.produtoSelecionado.price_promotional > 0) {
          this.produtoSelecionado.percentual_margem = this.produtoSelecionado.price_promotional + ((this.produtoSelecionado.price_promotional * this.b2b_margem_consumidor) / 100);
        } else {
          this.produtoSelecionado.percentual_margem = this.produtoSelecionado.price + ((this.produtoSelecionado.price * this.b2b_margem_consumidor) / 100);;
        }
      }

      this.modalService.open(content, {
        ariaLabelledBy: "titulo-simular",
        size: "xl",
        backdrop: "static"
      }).result.then((acao) => {
        if (acao == "Ok") {

        }
      }, () => {

      });
    }
  }

  /* Pagamento */

  alterarAbasPagamento(evento: any) {
    this.tabPagamentoSelecionada = evento.nextId;
  }

  /* Produtos (Busca (Grupo)) */

  buscarProdutoPorGrupo(grupo: number, event: any) {
    if (this.validarAcesso()) {
      if (this.produtoBuscarPorGrupo) {
        /* Produtos (Busca (Grupo)) */

        this.selecionarCategoriaPorImagem(false, this.produtoCategoriaSelecionada);
      } else if (this.produtoBuscarPorMedidaEnviado) {
        /* Produtos (Busca (Medida)) */

        this.buscarProdutoPorMedida(false);
      } else if (this.produtoBuscarPorNome) {
        /* Produtos (Busca (Nome)) */

        this.buscarProdutoPorNome(false);
      } else {
        /* Produtos (Categorias) */

        this.selecionarCategoria(false, this.produtoCategoriaSelecionada);
      }

      /* Grupos */

      if (grupo == 0) {
        /* Grupo */

        this.produtosGrupos0.forEach((item) => {
          if (item.grupo == event.target.value) {
            if (event.currentTarget.checked) {
              item.selecionado = true;
            } else {
              item.selecionado = false;
            }
          }
        });
      } else if (grupo == 1) {
        /* SubGrupo 1 */

        this.produtosGrupos1.forEach((item) => {
          if (item.grupo == event.target.value) {
            if (event.currentTarget.checked) {
              item.selecionado = true;
            } else {
              item.selecionado = false;
            }
          }
        });
      } else if (grupo == 2) {
        /* SubGrupo 2 */

        this.produtosGrupos2.forEach((item) => {
          if (item.grupo == event.target.value) {
            if (event.currentTarget.checked) {
              item.selecionado = true;
            } else {
              item.selecionado = false;
            }
          }
        });
      } else if (grupo == 3) {
        /* SubGrupo 3 */

        this.produtosGrupos3.forEach((item) => {
          if (item.grupo == event.target.value) {
            if (event.currentTarget.checked) {
              item.selecionado = true;
            } else {
              item.selecionado = false;
            }
          }
        });
      } else if (grupo == 4) {
        /* SubGrupo 4 */

        this.produtosGrupos4.forEach((item) => {
          if (item.grupo == event.target.value) {
            if (event.currentTarget.checked) {
              item.selecionado = true;
            } else {
              item.selecionado = false;
            }
          }
        });
      } else if (grupo == 5) {
        /* SubGrupo 5 */

        this.produtosGrupos5.forEach((item) => {
          if (item.grupo == event.target.value) {
            if (event.currentTarget.checked) {
              item.selecionado = true;
            } else {
              item.selecionado = false;
            }
          }
        });
      }

      /* Filtros */

      let comFiltro: boolean = false;

      let gruposSelecionados: number = 0;

      let produtosFiltro = JSON.parse(JSON.stringify(this.produtosBuscaAtual));

      produtosFiltro.forEach((item) => {
        item.filtro_por_grupo_estoque = false;
        item.filtro_por_subgrupo_estoque = false;
        item.filtro_por_subgrupo_estoque2 = false;
        item.filtro_por_subgrupo_estoque3 = false;
        item.filtro_por_subgrupo_estoque4 = false;
        item.filtro_por_subgrupo_estoque5 = false;
        item.filtrado = false;
      });

      /* Grupo */

      gruposSelecionados = this.produtosGrupos0.filter(produtoGrupo => produtoGrupo.selecionado).length;

      if (gruposSelecionados > 0) {
        this.produtosGrupos0.forEach((item) => {
          if (item.selecionado) {
            comFiltro = true;

            produtosFiltro.forEach((subItem) => {
              if (subItem.grupo_estoque == item.grupo) {
                subItem.filtro_por_grupo_estoque = true;
                subItem.filtrado = true;
              } else {
                if (!subItem.filtro_por_grupo_estoque) {
                  subItem.filtrado = false;
                }
              }
            });
          }
        });

        produtosFiltro = produtosFiltro.filter(produtoFiltro => produtoFiltro.filtrado);
      }

      /* SubGrupo 1 */

      gruposSelecionados = this.produtosGrupos1.filter(produtoGrupo => produtoGrupo.selecionado).length;

      if (gruposSelecionados > 0) {
        this.produtosGrupos1.forEach((item) => {
          if (item.selecionado) {
            comFiltro = true;

            produtosFiltro.forEach((subItem) => {
              if (subItem.subgrupo_estoque == item.grupo) {
                subItem.filtro_por_subgrupo_estoque = true;
                subItem.filtrado = true;
              } else {
                if (!subItem.filtro_por_subgrupo_estoque) {
                  subItem.filtrado = false;
                }
              }
            });
          }
        });

        produtosFiltro = produtosFiltro.filter(produtoFiltro => produtoFiltro.filtrado);
      }

      /* SubGrupo 2 */

      gruposSelecionados = this.produtosGrupos2.filter(produtoGrupo => produtoGrupo.selecionado).length;

      if (gruposSelecionados > 0) {
        this.produtosGrupos2.forEach((item) => {
          if (item.selecionado) {
            comFiltro = true;

            produtosFiltro.forEach((subItem) => {
              if (subItem.subgrupo_estoque2 == item.grupo) {
                subItem.filtro_por_subgrupo_estoque2 = true;
                subItem.filtrado = true;
              } else {
                if (!subItem.filtro_por_subgrupo_estoque2) {
                  subItem.filtrado = false;
                }
              }
            });
          }
        });

        produtosFiltro = produtosFiltro.filter(produtoFiltro => produtoFiltro.filtrado);
      }

      /* SubGrupo 3 */

      gruposSelecionados = this.produtosGrupos3.filter(produtoGrupo => produtoGrupo.selecionado).length;

      if (gruposSelecionados > 0) {
        this.produtosGrupos3.forEach((item) => {
          if (item.selecionado) {
            comFiltro = true;

            produtosFiltro.forEach((subItem) => {
              if (subItem.subgrupo_estoque3 == item.grupo) {
                subItem.filtro_por_subgrupo_estoque3 = true;
                subItem.filtrado = true;
              } else {
                if (!subItem.filtro_por_subgrupo_estoque3) {
                  subItem.filtrado = false;
                }
              }
            });
          }
        });

        produtosFiltro = produtosFiltro.filter(produtoFiltro => produtoFiltro.filtrado);
      }

      /* SubGrupo 4 */

      gruposSelecionados = this.produtosGrupos4.filter(produtoGrupo => produtoGrupo.selecionado).length;

      if (gruposSelecionados > 0) {
        this.produtosGrupos4.forEach((item) => {
          if (item.selecionado) {
            comFiltro = true;

            produtosFiltro.forEach((subItem) => {
              if (subItem.subgrupo_estoque4 == item.grupo) {
                subItem.filtro_por_subgrupo_estoque4 = true;
                subItem.filtrado = true;
              } else {
                if (!subItem.filtro_por_subgrupo_estoque4) {
                  subItem.filtrado = false;
                }
              }
            });
          }
        });

        produtosFiltro = produtosFiltro.filter(produtoFiltro => produtoFiltro.filtrado);
      }

      /* SubGrupo 5 */

      gruposSelecionados = this.produtosGrupos5.filter(produtoGrupo => produtoGrupo.selecionado).length;

      if (gruposSelecionados > 0) {
        this.produtosGrupos5.forEach((item) => {
          if (item.selecionado) {
            comFiltro = true;

            produtosFiltro.forEach((subItem) => {
              if (subItem.subgrupo_estoque5 == item.grupo) {
                subItem.filtro_por_subgrupo_estoque5 = true;
                subItem.filtrado = true;
              } else {
                if (!subItem.filtro_por_subgrupo_estoque5) {
                  subItem.filtrado = false;
                }
              }
            });
          }
        });

        produtosFiltro = produtosFiltro.filter(produtoFiltro => produtoFiltro.filtrado);
      }

      /* Exibição de grupos c/ produtos */

      let produtosFiltrados = JSON.parse(JSON.stringify(produtosFiltro));

      let produtoFiltro: ProdutoPromocional = null;

      /* Grupo */

      this.produtosGrupos0.forEach((item) => {
        produtoFiltro = produtosFiltrados.find(produtoFiltro => produtoFiltro.grupo_estoque == item.grupo);

        if (produtoFiltro == null) {
          item.exibir = false;
        } else {
          item.exibir = true;
        }
      });

      /* SubGrupo 1 */

      this.produtosGrupos1.forEach((item) => {
        produtoFiltro = produtosFiltrados.find(produtoFiltro => produtoFiltro.subgrupo_estoque == item.grupo);

        if (produtoFiltro == null) {
          item.exibir = false;
        } else {
          item.exibir = true;
        }
      });

      /* SubGrupo 2 */

      this.produtosGrupos2.forEach((item) => {
        produtoFiltro = produtosFiltrados.find(produtoFiltro => produtoFiltro.subgrupo_estoque2 == item.grupo);

        if (produtoFiltro == null) {
          item.exibir = false;
        } else {
          item.exibir = true;
        }
      });

      /* SubGrupo 3 */

      this.produtosGrupos3.forEach((item) => {
        produtoFiltro = produtosFiltrados.find(produtoFiltro => produtoFiltro.subgrupo_estoque3 == item.grupo);

        if (produtoFiltro == null) {
          item.exibir = false;
        } else {
          item.exibir = true;
        }
      });

      /* SubGrupo 4 */

      this.produtosGrupos4.forEach((item) => {
        produtoFiltro = produtosFiltrados.find(produtoFiltro => produtoFiltro.subgrupo_estoque4 == item.grupo);

        if (produtoFiltro == null) {
          item.exibir = false;
        } else {
          item.exibir = true;
        }
      });

      /* SubGrupo 5 */

      this.produtosGrupos5.forEach((item) => {
        produtoFiltro = produtosFiltrados.find(produtoFiltro => produtoFiltro.subgrupo_estoque5 == item.grupo);

        if (produtoFiltro == null) {
          item.exibir = false;
        } else {
          item.exibir = true;
        }
      });

      if (comFiltro) {
        this.produtos = JSON.parse(JSON.stringify(produtosFiltro));

        this.produtosBuscaAtual = JSON.parse(JSON.stringify(produtosFiltro));

        this.produtoCategoriaSelecionadaQtde = produtosFiltro.length;
      }
    }
  }

  /* Produtos (Busca (Medida)) */

  buscarProdutoPorMedida(externo: boolean) {
    if (this.produtoBuscarPorAroSelecionado != "" && this.produtoBuscarPorLarguraSelecionado != "" && this.produtoBuscarPorPerfilSelecionado != "") {
      /* Carrinho */

      this.carrinhoExibir = false;

      /* Produtos (Busca (Medida)) */

      this.produtoBuscarPorMedidaEnviado = true;

      /* Produtos (Busca (Nome)) */

      this.produtoBuscarPorNomeEnviado = false;

      /* Produtos (Categorias) */

      this.produtoCategoriaSelecionada = "";

      this.reiniciarProduto();

      this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));

      this.produtos = this.produtos.filter(produto => produto.subgrupo_estoque2 == this.produtoBuscarPorAroSelecionado && produto.subgrupo_estoque4 == this.produtoBuscarPorLarguraSelecionado && produto.subgrupo_estoque3 == this.produtoBuscarPorPerfilSelecionado);

      this.buscarImagens();

      if (externo) {
        this.agruparGrupos();
      }

      this.produtosBuscaAtual = JSON.parse(JSON.stringify(this.produtos));

      this.produtoCategoriaSelecionadaQtde = this.produtos.length;
    } else {
      this.reiniciar(true);
    }
  }

  selecionarProdutoPorAro(aro: string) {
    this.produtoBuscarPorAroSelecionado = aro;
  }

  selecionarProdutoPorLargura(largura: string) {
    this.produtoBuscarPorLarguraSelecionado = largura;

    /* Produtos (Busca (Medida)) */

    this.produtoBuscarPorAro = [];

    this.produtoBuscarPorAroSelecionado = "";

    this.produtoBuscarPorPerfil = [];

    this.produtoBuscarPorPerfilSelecionado = "";

    this.organizarMedidas();
  }

  selecionarProdutoPorPerfil(perfil: string) {
    this.produtoBuscarPorPerfilSelecionado = perfil;

    /* Produtos (Busca (Medida)) */

    this.produtoBuscarPorAro = [];

    this.produtoBuscarPorAroSelecionado = "";

    this.organizarMedidas();
  }

  /* Produtos (Busca (Nome)) */

  buscarProdutoPorNome(externo: boolean) {
    if (this.produtoBuscarPorNome.trim() != "") {
      /* Carrinho */

      this.carrinhoExibir = false;

      /* Produtos (Busca (Medida)) */

      this.produtoBuscarPorMedidaEnviado = false;

      /* Produtos (Busca (Nome)) */

      this.produtoBuscarPorNomeEnviado = true;

      /* Produtos (Categorias) */

      this.produtoCategoriaSelecionada = "";

      this.reiniciarProduto();

      this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));

      this.produtos = this.produtos.filter(produto => {
        let palavras = this.produtoBuscarPorNome.split(" ");

        let qtdePalavrasEncontradasProduto: number = 0;

        palavras.forEach((item) => {
          if (produto.name.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
            qtdePalavrasEncontradasProduto++;
          }
        });

        if (qtdePalavrasEncontradasProduto == palavras.length) {
          return true;
        } else {
          let produtoLocalizado: boolean = false;

          /* Grupo Estoque */

          if (produto.grupo_estoque != null) {
            palavras.forEach((item) => {
              if (produto.grupo_estoque.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
                produtoLocalizado = true;
              }
            });
          }

          /* Sub-grupo */

          if (produto.subgrupo_estoque != null) {
            palavras.forEach((item) => {
              if (produto.subgrupo_estoque.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
                produtoLocalizado = true;
              }
            });
          }

          /* Sub-grupo 2 */

          if (produto.subgrupo_estoque2 != null) {
            palavras.forEach((item) => {
              if (produto.subgrupo_estoque2.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
                produtoLocalizado = true;
              }
            });
          }

          /* Sub-grupo 3 */

          if (produto.subgrupo_estoque3 != null) {
            palavras.forEach((item) => {
              if (produto.subgrupo_estoque3.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
                produtoLocalizado = true;
              }
            });
          }

          /* Sub-grupo 4 */

          if (produto.subgrupo_estoque4 != null) {
            palavras.forEach((item) => {
              if (produto.subgrupo_estoque4.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
                produtoLocalizado = true;
              }
            });
          }

          /* Sub-grupo 5 */

          if (produto.subgrupo_estoque5 != null) {
            palavras.forEach((item) => {
              if (produto.subgrupo_estoque5.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
                produtoLocalizado = true;
              }
            });
          }

          if (produtoLocalizado) {
            return true;
          } else {
            if (produto.produtos_vinculados != null) {
              /* Produtos Vinculados */

              let porProdutoVinculado: ProdutoPromocional[] = produto.produtos_vinculados.filter(produtosVinculados => {
                let qtdePalavrasEncontradasCampanha: number = 0;

                palavras.forEach((subItem) => {
                  if (produtosVinculados.name.toLowerCase().indexOf(subItem.toLowerCase()) !== -1 && !produtosVinculados.bonificacao) {
                    qtdePalavrasEncontradasCampanha++;
                  }
                });

                return qtdePalavrasEncontradasCampanha == palavras.length;
              });

              if (porProdutoVinculado.length > 0) {
                return true;
              }
            }
          }
        }
      });

      this.buscarImagens();

      if (externo) {
        this.agruparGrupos();
      }

      this.produtosBuscaAtual = JSON.parse(JSON.stringify(this.produtos));

      this.produtoCategoriaSelecionadaQtde = this.produtos.length;
    } else {
      this.reiniciar(true);
    }
  }

  /* Produtos (Categorias) */

  selecionarCategoria(externo: boolean, categoria: string) {
    if (this.validarAcesso()) {
      this.produtoCategoriaSelecionada = categoria;
      this.produtoCategoriaSelecionadaQtde = 0;

      if (externo) {
        this.reiniciar(false);
      } else {
        this.produtos = JSON.parse(JSON.stringify(this.produtosOriginal));
      }

      if (categoria != null) {
        if (this.produtosCategorias != null) {
          let produtoCategoria: ProdutoCategoria = this.produtosCategorias.find(produtoCategoria => produtoCategoria.categoria == categoria);

          if (produtoCategoria != null) {
            this.produtos = this.produtos.filter(produto => produto.categoria == produtoCategoria.categoria);
          } else {
            if (categoria == this.aviseme) {
              /* Avise-me */

              this.produtos = this.produtos.filter(produto => produto.disponibilidade);
            } else if (categoria == this.promocoes) {
              /* Promoções */

              this.produtos = this.produtos.filter(produto => produto.promocao);

              if (this.configuracao != null && this.configuracao.b2b_segmentado && this.segment != "") {
                let produtoSegmento: ProdutoSegmento = null;

                let produtosFiltrados = this.produtos;

                produtosFiltrados.forEach((item) => {
                  produtoSegmento = this.produtosSegmentos.find(produtoSegmento => produtoSegmento.id_produto == item.id);

                  if (produtoSegmento == null) {
                    let produtoNaoSegmentado = this.produtos.findIndex(produto => produto.id == item.id);

                    if (produtoNaoSegmentado != -1) {
                      this.produtos.splice(produtoNaoSegmentado, 1);
                    }
                  }
                });
              }
            } else if (categoria == this.reposicoes) {
              /* Reposições */

              this.produtos = this.produtos.filter(produto => produto.ultima_compra != null);
            } else if (categoria == this.maisVendidos) {
              /* Mais Vendidos */

              this.produtos = this.produtos.filter(produto => produto.mais_vendidos);

              if (this.configuracao != null && this.configuracao.b2b_segmentado && this.segment != "") {
                let produtoSegmento: ProdutoSegmento = null;

                let produtosFiltrados = this.produtos;

                produtosFiltrados.forEach((item) => {
                  produtoSegmento = this.produtosSegmentos.find(produtoSegmento => produtoSegmento.id_produto == item.id);

                  if (produtoSegmento == null) {
                    let produtoNaoSegmentado = this.produtos.findIndex(produto => produto.id == item.id);

                    if (produtoNaoSegmentado != -1) {
                      this.produtos.splice(produtoNaoSegmentado, 1);
                    }
                  }
                });
              }
            }
          }

          this.produtoCategoriaSelecionadaQtde = this.produtos.length;

          this.buscarImagens();

          if (externo) {
            this.agruparGrupos();
          }
        }
      }

      this.produtosBuscaAtual = JSON.parse(JSON.stringify(this.produtos));

      if (externo && this.produtoCategoriaSelecionada == this.promocoes) {
        this.moverParaCategoria();
      }
    }
  }

  selecionarCategoriaPorImagem(externo: boolean, categoria_banner: string) {
    if (this.validarAcesso()) {
      this.produtoCategoriaSelecionada = categoria_banner;
      this.produtoCategoriaSelecionadaQtde = 0;

      this.reiniciar(false);

      if (categoria_banner != null) {
        this.produtos = this.produtos.filter(produto => {
          if (produto.categoria_banner != null) {
            let produtosCategorias: string[] = [];

            produtosCategorias = produto.categoria_banner.split(";");

            let produtoCategoria = produtosCategorias.find(produtoCategoria => produtoCategoria.toLowerCase() == categoria_banner.toLowerCase());

            if (produtoCategoria != null) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });

        this.produtoCategoriaSelecionadaQtde = this.produtos.length;

        this.produtosBuscaAtual = JSON.parse(JSON.stringify(this.produtos));

        this.produtoBuscarPorGrupo = true;

        this.buscarImagens();

        if (externo) {
          this.agruparGrupos();
        }
      }
    }
  }

  /* Prazo de Pagamento */

  alterarPrazoPagamento(id_prazo_pagamento: string) {
    let idSelecionado = parseInt(id_prazo_pagamento);

    if (idSelecionado > 0) {
      this.prazoPagamentoSelecionado = this.prazosPagamento.find(prazoPagamento => prazoPagamento.idprazopagamento == idSelecionado);
    } else {
      this.definirPrazoPagamento();
    }

    this.aplicarFatorFinanceiro();
    this.totalizarCarrinho();
  }

  aplicarFatorFinanceiro() {
    if (this.prazoPagamentoSelecionado != null) {
      if (this.prazoPagamentoSelecionado.fator_financeiro != null && this.prazoPagamentoSelecionado.fator_financeiro != 0) {
        let fator: number = this.prazoPagamentoSelecionado.fator_financeiro;

        /* Produtos */

        this.produtos.forEach((item) => {
          if (item.price_promotional > 0) {
            item.price_promotional = item.price_original;

            if (fator >= 0) {
              item.price_promotional = (item.price_promotional + ((item.price_promotional * fator) / 100));
            } else {
              item.price_promotional = (item.price_promotional - ((item.price_promotional * Math.abs(fator)) / 100));
            }

            item.valor_total = item.price_promotional;
          } else {
            item.price = item.price_original;

            if (fator >= 0) {
              item.price = (item.price + ((item.price * fator) / 100));
            } else {
              item.price = (item.price - ((item.price * Math.abs(fator)) / 100));
            }

            item.valor_total = item.price;
          }

          if (item.produtos_vinculados != null) {
            /* Produtos Vinculados */

            item.produtos_vinculados.forEach((subItem) => {
              subItem.price_promotional = subItem.price_original;

              if (fator >= 0) {
                subItem.price_promotional = (subItem.price_promotional + ((subItem.price_promotional * fator) / 100));
              } else {
                subItem.price_promotional = (subItem.price_promotional - ((subItem.price_promotional * Math.abs(fator)) / 100));
              }

              subItem.valor_total = subItem.price_promotional;
            });

            item.valor_total_produtos = item.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

            item.valor_total = item.valor_total_produtos * item.qtde;
          }
        });

        /* Produto (Original) */

        this.produtosOriginal.forEach((item) => {
          if (item.price_promotional > 0) {
            item.price_promotional = item.price_original;

            if (fator >= 0) {
              item.price_promotional = (item.price_promotional + ((item.price_promotional * fator) / 100));
            } else {
              item.price_promotional = (item.price_promotional - ((item.price_promotional * Math.abs(fator)) / 100));
            }

            item.valor_total = item.price_promotional;
          } else {
            item.price = item.price_original;

            if (fator >= 0) {
              item.price = (item.price + ((item.price * fator) / 100));
            } else {
              item.price = (item.price - ((item.price * Math.abs(fator)) / 100));
            }

            item.valor_total = item.price;
          }

          if (item.produtos_vinculados != null) {
            /* Produtos Vinculados */

            item.produtos_vinculados.forEach((subItem) => {
              subItem.price_promotional = subItem.price_original;

              if (fator >= 0) {
                subItem.price_promotional = (subItem.price_promotional + ((subItem.price_promotional * fator) / 100));
              } else {
                subItem.price_promotional = (subItem.price_promotional - ((subItem.price_promotional * Math.abs(fator)) / 100));
              }

              subItem.valor_total = subItem.price_promotional;
            });

            item.valor_total_produtos = item.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

            item.valor_total = item.valor_total_produtos * item.qtde;
          }
        });

        /* Carrinho */

        this.carrinho.forEach((item) => {
          if (item.price_promotional > 0) {
            item.price_promotional = item.price_original;

            if (fator >= 0) {
              item.price_promotional = (item.price_promotional + ((item.price_promotional * fator) / 100));
            } else {
              item.price_promotional = (item.price_promotional - ((item.price_promotional * Math.abs(fator)) / 100));
            }

            item.valor_total = item.price_promotional * item.qtde;
          } else {
            item.price = item.price_original;

            if (fator >= 0) {
              item.price = (item.price + ((item.price * fator) / 100));
            } else {
              item.price = (item.price - ((item.price * Math.abs(fator)) / 100));
            }

            item.valor_total = item.price * item.qtde;
          }

          if (item.produtos_vinculados != null) {
            /* Produtos Vinculados */

            item.produtos_vinculados.forEach((subItem) => {
              subItem.price_promotional = subItem.price_original;

              if (fator >= 0) {
                subItem.price_promotional = (subItem.price_promotional + ((subItem.price_promotional * fator) / 100));
              } else {
                subItem.price_promotional = (subItem.price_promotional - ((subItem.price_promotional * Math.abs(fator)) / 100));
              }

              subItem.valor_total = subItem.price_promotional * subItem.qtde;
            });

            item.valor_total_produtos = item.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

            item.valor_total = item.valor_total_produtos * item.qtde;
          }
        });
      } else {
        /* Produtos */

        this.produtos.forEach((item) => {
          if (item.price_promotional > 0) {
            item.price_promotional = item.price_original;

            item.valor_total = item.price_promotional;
          } else {
            item.price = item.price_original;

            item.valor_total = item.price;
          }

          if (item.produtos_vinculados != null) {
            /* Produtos Vinculados */

            item.produtos_vinculados.forEach((subItem) => {
              subItem.price_promotional = subItem.price_original;

              subItem.valor_total = subItem.price_promotional;
            });

            item.valor_total_produtos = item.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

            item.valor_total = item.valor_total_produtos * item.qtde;
          }
        });

        /* Produto (Original) */

        this.produtosOriginal.forEach((item) => {
          if (item.price_promotional > 0) {
            item.price_promotional = item.price_original;

            item.valor_total = item.price_promotional;
          } else {
            item.price = item.price_original;

            item.valor_total = item.price;
          }

          if (item.produtos_vinculados != null) {
            /* Produtos Vinculados */

            item.produtos_vinculados.forEach((subItem) => {
              subItem.price_promotional = subItem.price_original;

              subItem.valor_total = subItem.price_promotional;
            });

            item.valor_total_produtos = item.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

            item.valor_total = item.valor_total_produtos * item.qtde;
          }
        });

        /* Carrinho */

        this.carrinho.forEach((item) => {
          if (item.price_promotional > 0) {
            item.price_promotional = item.price_original;

            item.valor_total = item.price_promotional * item.qtde;
          } else {
            item.price = item.price_original;

            item.valor_total = item.price * item.qtde;
          }

          if (item.produtos_vinculados != null) {
            /* Produtos Vinculados */

            item.produtos_vinculados.forEach((subItem) => {
              subItem.price_promotional = subItem.price_original;

              subItem.valor_total = subItem.price_promotional * subItem.qtde;
            });

            item.valor_total_produtos = item.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

            item.valor_total = item.valor_total_produtos * item.qtde;
          }
        });
      }
    }
  }

  definirPrazoPagamento() {
    if (this.formaPagamentoSelecionada != null) {
      this.prazoPagamentoSelecionado = this.prazosPagamento.find(prazoPagamento => prazoPagamento.padrao && prazoPagamento.id_prazopagamento_forma == this.formaPagamentoSelecionada.id); /* Padrão */

      if (this.prazoPagamentoSelecionado == null || !this.prazoPagamentoSelecionado.exibir) {
        this.prazosPagamento.forEach((item) => {
          if (item.exibir) {
            this.prazoPagamentoSelecionado = item;

            return;
          }
        });
      }
    }

    this.aplicarFatorFinanceiro();
  }

  validarPrazoPagamento() {
    if (this.formaPagamentoSelecionada != null) {
      this.prazosPagamento.forEach((item) => {
        if (this.carrinhoValor >= item.valorinicial) {
          if (this.formaPagamentoSelecionada.id == item.id_prazopagamento_forma) {
            if (this.prazoPagamentoParcelaSelecionado != null) {
              if (item.descricao_qt_parcelas == this.prazoPagamentoParcelaSelecionado.descricao_qt_parcelas) {
                item.exibir = true;
              } else {
                item.exibir = false;
              }
            } else {
              item.exibir = true;
            }
          } else {
            item.exibir = false;
          }
        } else {
          item.exibir = false;
        }
      });
    }

    if (this.prazoPagamentoSelecionado != null) {
      if (!this.prazoPagamentoSelecionado.exibir) {
        this.definirPrazoPagamento();
      }
    } else {
      this.definirPrazoPagamento();
    }
  }

  /* Prazo de Pagamento (Parcelas) */

  alterarPrazoPagamentoParcela(id_prazo_pagamento: string) {
    let idSelecionado = parseInt(id_prazo_pagamento);

    if (idSelecionado > 0) {
      this.prazoPagamentoParcelaSelecionado = this.prazosPagamentoParcela.find(prazoPagamentoParcela => prazoPagamentoParcela.idprazopagamento == idSelecionado);
    } else {
      this.definirPrazoPagamentoParcela();
    }

    this.aplicarFatorFinanceiro();
    this.totalizarCarrinho();
  }

  definirPrazoPagamentoParcela() {
    this.prazosPagamentoParcela.forEach((item) => {
      if (item.exibir) {
        this.prazoPagamentoParcelaSelecionado = item;

        return;
      }
    });
  }

  validarPrazoPagamentoParcela() {
    if (this.configuracao != null && this.configuracao.b2b_seleciona_parcelas) {
      if (this.formaPagamentoSelecionada != null) {
        this.prazosPagamentoParcela.forEach((item) => {
          item.exibir = false;

          if (this.carrinhoValor >= item.valorinicial) {
            if (this.formaPagamentoSelecionada.id == item.id_prazopagamento_forma) {
              let prazoPagamentoParcela = this.prazosPagamentoParcela.find(prazoPagamentoParcela => prazoPagamentoParcela.descricao_qt_parcelas == item.descricao_qt_parcelas && prazoPagamentoParcela.id_prazopagamento_forma == item.id_prazopagamento_forma && prazoPagamentoParcela.exibir);

              if (prazoPagamentoParcela == null) {
                item.exibir = true;
              }
            }
          }
        });

        this.prazosPagamentoParcela.sort((a, b) => {
          const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

          return compare(a["descricao_qt_parcelas"], b["descricao_qt_parcelas"]);
        });
      }

      if (this.prazoPagamentoParcelaSelecionado != null) {
        if (!this.prazoPagamentoParcelaSelecionado.exibir) {
          this.definirPrazoPagamentoParcela();
        }
      } else {
        this.definirPrazoPagamentoParcela();
      }
    }
  }

  /* Qtde */

  decremetarQtde(id?: number, id_campanha?: number) {
    if (this.produtoSelecionado != null) {
      /* Produto Selecionado */

      if (id != null && id_campanha != null) {
        /* Campanhas (Produtos Vinculados) */

        let produtoCampanha = this.produtos.find(produto => produto.id_campanha == id_campanha);

        if (produtoCampanha != null) {
          /* Produtos */

          let produtoVinculado = produtoCampanha.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id);

          if (produtoVinculado != null) {
            produtoVinculado.qtde--;

            if (produtoVinculado.qtde <= 0 || produtoVinculado.qtde < produtoVinculado.qtde_minima) {
              produtoVinculado.qtde = produtoVinculado.qtde_minima;
            }

            if (this.produtoSelecionado.grupo_estoque == this.combo) {
              this.validarQtdeMinimaCombo();
            }

            /* Bonificação */

            this.localizarProdutoBonificado();
            this.definirQtdeBonificacaoMultiplicavel(null);

            /* Totalizar (Produtos Vinculados) */

            produtoVinculado.valor_total = produtoVinculado.price_promotional * produtoVinculado.qtde;

            this.totalizarCampanha();
          }
        }
      } else {
        /* Produtos */

        if (this.produtoSelecionado.categoria != this.campanhas) {
          this.qtde--;

          if (this.qtde < 1) {
            this.qtde = 1;
          }

          if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N" && this.produtoSelecionado.estoque < this.qtde) {
            this.toastr.error("", "Qtde acima do estoque! Disponível: " + this.produtoSelecionado.estoque);

            this.qtde = this.produtoSelecionado.estoque;
          }

          this.validarQtdePromocional(null);
        } else {
          if (this.produtoSelecionado.qtde_minima_atual < this.produtoSelecionado.qtde_minima) {
            this.qtde = 1;

            this.toastr.error("", "Qtde Mínima de Itens inválida!");

            return;
          } else {
            this.qtde--;

            if (this.qtde < 1) {
              this.qtde = 1;
            }

            /* Bonificação */

            this.definirQtdeBonificacaoMultiplicavel(null);
          }
        }

        /* Totalizar */

        if (this.produtoSelecionado.grupo_estoque == this.combo) {
          this.produtoSelecionado.valor_total = this.produtoSelecionado.valor_total_produtos * this.qtde;
        } else {
          if (this.produtoSelecionado.price_promotional > 0) {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.price_promotional * this.qtde;
          } else {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.price * this.qtde;
          }
        }

        this.totalizarCarrinho();
      }
    } else {
      /* Produto Carrinho */

      if (id_campanha != null) {
        /* Campanhas */

        let produtoCampanhaCarrinho = this.carrinho.find(carrinho => carrinho.id_campanha == id_campanha);

        if (produtoCampanhaCarrinho != null) {
          produtoCampanhaCarrinho.qtde--;

          if (produtoCampanhaCarrinho.qtde < 1) {
            produtoCampanhaCarrinho.qtde = 1;
          }

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(id_campanha);

          /* Totalizar */

          if (produtoCampanhaCarrinho.grupo_estoque == this.combo) {
            produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.valor_total_produtos * produtoCampanhaCarrinho.qtde;
          } else {
            if (produtoCampanhaCarrinho.price_promotional > 0) {
              produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.price_promotional * produtoCampanhaCarrinho.qtde;
            } else {
              produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.price * produtoCampanhaCarrinho.qtde;
            }
          }
        }
      } else if (id != null) {
        /* Produtos */

        let produtoCarrinho = this.carrinho.find(carrinho => carrinho.id == id);

        if (produtoCarrinho != null) {
          produtoCarrinho.qtde--;

          if (produtoCarrinho.qtde < 1) {
            produtoCarrinho.qtde = 1;
          }

          if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N" && produtoCarrinho.estoque < produtoCarrinho.qtde) {
            this.toastr.error("", "Qtde acima do estoque! Disponível: " + produtoCarrinho.estoque);

            produtoCarrinho.qtde = produtoCarrinho.estoque;
          }

          if (produtoCarrinho.categoria != this.campanhas) {
            this.validarQtdePromocional(id);
          }

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(null);

          /* Totalizar */

          if (produtoCarrinho.grupo_estoque == this.combo) {
            produtoCarrinho.valor_total = produtoCarrinho.valor_total_produtos * produtoCarrinho.qtde;
          } else {
            if (produtoCarrinho.price_promotional > 0) {
              produtoCarrinho.valor_total = produtoCarrinho.price_promotional * produtoCarrinho.qtde;
            } else {
              produtoCarrinho.valor_total = produtoCarrinho.price * produtoCarrinho.qtde;
            }
          }
        }
      }

      this.totalizarCarrinho();
    }
  }

  incrementarQtde(id?: number, id_campanha?: number) {
    if (this.produtoSelecionado != null) {
      /* Produto Selecionado */

      if (id != null && id_campanha != null) {
        /* Campanhas (Produtos Vinculados) */

        let produtoCampanha = this.produtos.find(produto => produto.id_campanha == id_campanha);

        if (produtoCampanha != null) {
          /* Produtos */

          let produtoVinculado = produtoCampanha.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id);

          if (produtoVinculado != null) {
            produtoVinculado.qtde++;

            if (produtoVinculado.qtde > 9999) {
              this.toastr.error("", "Qtde inválida!");

              produtoVinculado.qtde = 1;
            }

            if (this.produtoSelecionado.grupo_estoque == this.combo) {
              this.validarQtdeMinimaCombo();
            }

            /* Bonificação */

            this.localizarProdutoBonificado();
            this.definirQtdeBonificacaoMultiplicavel(null);

            /* Totalizar (Produtos Vinculados) */

            produtoVinculado.valor_total = produtoVinculado.price_promotional * produtoVinculado.qtde;

            this.totalizarCampanha();
          }
        }
      } else {
        /* Produtos */

        if (this.produtoSelecionado.categoria != this.campanhas) {
          this.qtde++;

          if (this.qtde > 9999) {
            this.toastr.error("", "Qtde inválida!");

            this.qtde = 1;
          }

          if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N" && this.produtoSelecionado.estoque < this.qtde) {
            this.toastr.error("", "Qtde acima do estoque! Disponível: " + this.produtoSelecionado.estoque);

            this.qtde = this.produtoSelecionado.estoque;
          }

          this.validarQtdePromocional(null);
        } else {
          if (this.produtoSelecionado.qtde_minima_atual < this.produtoSelecionado.qtde_minima) {
            this.qtde = 1;

            this.toastr.error("", "Qtde Mínima de Itens inválida!");

            return;
          } else {
            this.qtde++;

            if (this.qtde > 9999) {
              this.toastr.error("", "Qtde inválida!");

              this.qtde = 1;
            }

            /* Bonificação */

            this.definirQtdeBonificacaoMultiplicavel(null);
          }
        }

        /* Totalizar */

        if (this.produtoSelecionado.grupo_estoque == this.combo) {
          this.produtoSelecionado.valor_total = this.produtoSelecionado.valor_total_produtos * this.qtde;
        } else {
          if (this.produtoSelecionado.price_promotional > 0) {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.price_promotional * this.qtde;
          } else {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.price * this.qtde;
          }
        }

        this.totalizarCarrinho();
      }
    } else {
      /* Produto Carrinho */

      if (id_campanha != null) {
        /* Campanhas */

        let produtoCampanhaCarrinho = this.carrinho.find(carrinho => carrinho.id_campanha == id_campanha);

        if (produtoCampanhaCarrinho != null) {
          produtoCampanhaCarrinho.qtde++;

          if (produtoCampanhaCarrinho.qtde > 9999) {
            this.toastr.error("", "Qtde inválida!");

            produtoCampanhaCarrinho.qtde = 1;
          }

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(id_campanha);

          /* Totalizar */

          if (produtoCampanhaCarrinho.grupo_estoque == this.combo) {
            produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.valor_total_produtos * produtoCampanhaCarrinho.qtde;
          } else {
            if (produtoCampanhaCarrinho.price_promotional > 0) {
              produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.price_promotional * produtoCampanhaCarrinho.qtde;
            } else {
              produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.price * produtoCampanhaCarrinho.qtde;
            }
          }
        }
      } else if (id != null) {
        /* Produtos */

        let produtoCarrinho = this.carrinho.find(carrinho => carrinho.id == id);

        if (produtoCarrinho != null) {
          produtoCarrinho.qtde++;

          if (produtoCarrinho.qtde > 9999) {
            this.toastr.error("", "Qtde inválida!");

            produtoCarrinho.qtde = 1;
          }

          if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N" && produtoCarrinho.estoque < produtoCarrinho.qtde) {
            this.toastr.error("", "Qtde acima do estoque! Disponível: " + produtoCarrinho.estoque);

            produtoCarrinho.qtde = produtoCarrinho.estoque;
          }

          if (produtoCarrinho.categoria != this.campanhas) {
            this.validarQtdePromocional(id);
          }

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(null);

          /* Totalizar */

          if (produtoCarrinho.grupo_estoque == this.combo) {
            produtoCarrinho.valor_total = produtoCarrinho.valor_total_produtos * produtoCarrinho.qtde;
          } else {
            if (produtoCarrinho.price_promotional > 0) {
              produtoCarrinho.valor_total = produtoCarrinho.price_promotional * produtoCarrinho.qtde;
            } else {
              produtoCarrinho.valor_total = produtoCarrinho.price * produtoCarrinho.qtde;
            }
          }
        }
      }

      this.totalizarCarrinho();
    }
  }

  /* Qtde (Bonificação) */

  definirQtdeBonificacaoMultiplicavel(id_campanha?: number) {
    if (this.produtoSelecionado != null) {
      /* Produto Selecionado */

      if (this.produtoSelecionado.preco_bonificacao == "B") {
        let qtdeBonificacaoMultiplicavel: number = 0;

        if (this.produtoSelecionado.bonificacao_multiplicavel) {
          /* Multiplicável */

          if (this.produtoSelecionado.qtde_minima > 0) {
            qtdeBonificacaoMultiplicavel = this.produtoSelecionado.qtde_minima_atual / this.produtoSelecionado.qtde_minima;

            qtdeBonificacaoMultiplicavel = parseInt(qtdeBonificacaoMultiplicavel.toString()) * this.qtde;

            if (qtdeBonificacaoMultiplicavel < 1) {
              qtdeBonificacaoMultiplicavel = 1;
            }
          } else {
            qtdeBonificacaoMultiplicavel = this.qtde;
          }

          this.produtoSelecionado.qtde_bonificacao_multiplicavel = qtdeBonificacaoMultiplicavel;
        } else {
          this.produtoSelecionado.qtde_bonificacao_multiplicavel = 1;
        }
      }
    } else {
      /* Produto Carrinho */

      if (id_campanha != null) {
        /* Campanhas */

        let produtoCampanhaCarrinho = this.carrinho.find(carrinho => carrinho.id_campanha == id_campanha);

        if (produtoCampanhaCarrinho != null) {
          if (produtoCampanhaCarrinho.preco_bonificacao == "B") {
            let qtdeBonificacaoMultiplicavel: number = 0;

            if (produtoCampanhaCarrinho.bonificacao_multiplicavel) {
              /* Multiplicável */

              if (produtoCampanhaCarrinho.qtde_minima > 0) {
                qtdeBonificacaoMultiplicavel = produtoCampanhaCarrinho.qtde_minima_atual / produtoCampanhaCarrinho.qtde_minima;

                qtdeBonificacaoMultiplicavel = parseInt(qtdeBonificacaoMultiplicavel.toString()) * produtoCampanhaCarrinho.qtde;

                if (qtdeBonificacaoMultiplicavel < 1) {
                  qtdeBonificacaoMultiplicavel = 1;
                }
              } else {
                qtdeBonificacaoMultiplicavel = produtoCampanhaCarrinho.qtde;
              }

              if (!produtoCampanhaCarrinho.bonificacao_selecionavel) {
                produtoCampanhaCarrinho.qtde_bonificacao_multiplicavel = qtdeBonificacaoMultiplicavel;
              }
            } else {
              produtoCampanhaCarrinho.qtde_bonificacao_multiplicavel = 1;
            }
          }
        }
      }
    }
  }

  localizarProdutoBonificado() {
    if (this.produtoSelecionado != null) {
      if (this.produtoSelecionado.preco_bonificacao == "B" && !this.produtoSelecionado.bonificacao_selecionavel) {
        if (this.produtoSelecionado.produtos_vinculados != null) {
          /* Produtos Vinculados */

          this.produtoSelecionado.produtos_vinculados.forEach((item) => {
            item.bonificacao_selecionada = false
          });

          let produtosVinculados = this.produtoSelecionado.produtos_vinculados.filter(produtosVinculados => produtosVinculados.qtde > 0 && !produtosVinculados.bonificacao);

          produtosVinculados = JSON.parse(JSON.stringify(produtosVinculados));

          if (produtosVinculados.length > 0) {
            produtosVinculados.sort((a, b) => {
              const compare = (v1, v2) => v1 == null ? -1 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

              return compare(a["price_promotional"], b["price_promotional"]);
            });

            let produtoVinculado = this.produtoSelecionado.produtos_vinculados.find(produtoVinculado => produtoVinculado.id == produtosVinculados[0].id);

            if (produtoVinculado != null) {
              produtoVinculado.bonificacao_selecionada = true;
            }
          }
        }
      }
    }
  }

  /* Qtde (Combo) */

  validarQtdeMinimaCombo() {
    if (this.produtoSelecionado != null) {
      let qtdeMinimaAtual: number = this.produtoSelecionado.produtos_vinculados.filter(produtosVinculados => !produtosVinculados.bonificacao).reduce((sum, current) => (sum + current.qtde), 0);

      this.produtoSelecionado.qtde_minima_atual = qtdeMinimaAtual;
    }
  }

  validarQtdeProduto(id?: number, id_campanha?: number) {
    if (this.produtoSelecionado != null) {
      /* Produto Selecionado */

      if (id != null && id_campanha != null) {
        /* Campanhas (Produtos Vinculados) */

        let produtoCampanha = this.produtos.find(produto => produto.id_campanha == id_campanha);

        if (produtoCampanha != null) {
          /* Produtos */

          let produtoVinculado = produtoCampanha.produtos_vinculados.find(produtosVinculados => produtosVinculados.id == id);

          if (produtoVinculado != null) {
            if (produtoVinculado.qtde_minima > 0) {
              if (produtoVinculado.qtde == null || produtoVinculado.qtde <= 0 || produtoVinculado.qtde > 9999 || produtoVinculado.qtde < produtoVinculado.qtde_minima) {
                produtoVinculado.qtde = produtoVinculado.qtde_minima;
              }
            }

            if (this.produtoSelecionado.grupo_estoque == this.combo) {
              this.validarQtdeMinimaCombo();
            }

            /* Bonificação */

            this.localizarProdutoBonificado();
            this.definirQtdeBonificacaoMultiplicavel(null);

            /* Totalizar (Produtos Vinculados) */

            produtoVinculado.valor_total = produtoVinculado.price_promotional * produtoVinculado.qtde;

            this.totalizarCampanha();
          }
        }
      } else {
        /* Produtos */

        if (this.qtde == null || this.qtde <= 0 || this.qtde > 9999) {
          this.qtde = 1;
        }

        if (this.produtoSelecionado.categoria != this.campanhas) {
          this.validarQtdePromocional(null);
        } else {
          if (this.produtoSelecionado.qtde_minima_atual < this.produtoSelecionado.qtde_minima) {
            if (this.qtde > 1) {
              this.qtde = 1;

              /* Bonificação */

              this.definirQtdeBonificacaoMultiplicavel(null);

              this.toastr.error("", "Qtde Mínima de Itens inválida!");

              return;
            }
          } else {
            /* Bonificação */

            this.definirQtdeBonificacaoMultiplicavel(null);
          }
        }

        /* Totalizar */

        if (this.produtoSelecionado.grupo_estoque == this.combo) {
          this.produtoSelecionado.valor_total = this.produtoSelecionado.valor_total_produtos * this.qtde;
        } else {
          if (this.produtoSelecionado.price_promotional > 0) {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.price_promotional * this.qtde;
          } else {
            this.produtoSelecionado.valor_total = this.produtoSelecionado.price * this.qtde;
          }
        }

        this.totalizarCarrinho();
      }
    } else {
      /* Produto Carrinho */

      if (id_campanha != null) {
        /* Campanhas */

        let produtoCampanhaCarrinho = this.carrinho.find(carrinho => carrinho.id_campanha == id_campanha);

        if (produtoCampanhaCarrinho != null) {
          if (produtoCampanhaCarrinho.qtde == null || produtoCampanhaCarrinho.qtde <= 0 || produtoCampanhaCarrinho.qtde > 9999) {
            produtoCampanhaCarrinho.qtde = 1;
          }

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(id_campanha);

          /* Totalizar */

          if (produtoCampanhaCarrinho.grupo_estoque == this.combo) {
            produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.valor_total_produtos * produtoCampanhaCarrinho.qtde;
          } else {
            if (produtoCampanhaCarrinho.price_promotional > 0) {
              produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.price_promotional * produtoCampanhaCarrinho.qtde;
            } else {
              produtoCampanhaCarrinho.valor_total = produtoCampanhaCarrinho.price * produtoCampanhaCarrinho.qtde;
            }
          }
        }
      } else if (id != null) {
        /* Produtos */

        let produtoCarrinho = this.carrinho.find(carrinho => carrinho.id == id);

        if (produtoCarrinho != null) {
          if (produtoCarrinho.qtde == null || produtoCarrinho.qtde <= 0 || produtoCarrinho.qtde > 9999) {
            produtoCarrinho.qtde = 1;
          }

          if (this.configuracao != null && this.configuracao.permitir_produtos_sem_estoque == "N" && produtoCarrinho.estoque < produtoCarrinho.qtde) {
            this.toastr.error("", "Qtde acima do estoque! Disponível: " + produtoCarrinho.estoque);

            produtoCarrinho.qtde = produtoCarrinho.estoque;
          }

          if (produtoCarrinho.categoria != this.campanhas) {
            this.validarQtdePromocional(id);
          }

          /* Bonificação */

          this.definirQtdeBonificacaoMultiplicavel(null);

          /* Totalizar */

          if (produtoCarrinho.grupo_estoque == this.combo) {
            produtoCarrinho.valor_total = produtoCarrinho.valor_total_produtos * produtoCarrinho.qtde;
          } else {
            if (produtoCarrinho.price_promotional > 0) {
              produtoCarrinho.valor_total = produtoCarrinho.price_promotional * produtoCarrinho.qtde;
            } else {
              produtoCarrinho.valor_total = produtoCarrinho.price * produtoCarrinho.qtde;
            }
          }
        }
      }

      this.totalizarCarrinho();
    }
  }

  validarQtdePromocional(id?: number) {
    if (this.produtoSelecionado != null) {
      /* Produto Selecionado */

      if (this.produtoSelecionado.tabela_promocao_qtde.length > 0) {
        let precoPromocional: number = 0;

        this.produtoSelecionado.tabela_promocao_qtde.forEach((item) => {
          if (item.qtd_minima <= this.qtde) {
            precoPromocional = item.preco;
          }
        });

        let porcentagemPromocional: number = 0;

        if (precoPromocional > 0) {
          porcentagemPromocional = (1 - (this.produtoSelecionado.price / precoPromocional)) * 100;

          this.produtoSelecionado.price_promotional_percentual = porcentagemPromocional;

          this.produtoSelecionado.price_promotional = precoPromocional;
        } else if (this.produtoSelecionado.price_promotional_original > 0) {
          porcentagemPromocional = (1 - (this.produtoSelecionado.price / this.produtoSelecionado.price_promotional_original)) * 100;

          this.produtoSelecionado.price_promotional_percentual = porcentagemPromocional;

          this.produtoSelecionado.price_promotional = this.produtoSelecionado.price_promotional_original;
        } else {
          this.produtoSelecionado.price_promotional_percentual = 0;

          this.produtoSelecionado.price_promotional = 0;
        }
      }
    } else if (id != null) {
      /* Produto Carrinho */

      let produto = this.carrinho.find(carrinho => carrinho.id == id);

      if (produto != null) {
        if (produto.tabela_promocao_qtde.length > 0) {
          let precoPromocional: number = 0;

          produto.tabela_promocao_qtde.forEach((item) => {
            if (item.qtd_minima <= produto.qtde) {
              precoPromocional = item.preco;
            }
          });

          let porcentagemPromocional: number = 0;

          if (precoPromocional > 0) {
            porcentagemPromocional = (1 - (produto.price / precoPromocional)) * 100;

            produto.price_promotional_percentual = porcentagemPromocional;

            produto.price_promotional = precoPromocional;
          } else if (produto.price_promotional_original > 0) {
            porcentagemPromocional = (1 - (produto.price / produto.price_promotional_original)) * 100;

            produto.price_promotional_percentual = porcentagemPromocional;

            produto.price_promotional = produto.price_promotional_original;
          } else {
            produto.price_promotional_percentual = 0;

            produto.price_promotional = 0;
          }
        }
      }
    }
  }

  /* Sessão */

  validarAcesso() : boolean {
    if (!this.authService.statusAutorizacao()) {
      this.authService.desautorizar();

      return false;
    }

    return true;
  }

  /* Totalizar */

  totalizarCampanha() {
    if (this.produtoSelecionado != null) {
      this.produtoSelecionado.valor_total_produtos = this.produtoSelecionado.produtos_vinculados.reduce((sum, current) => (sum + current.valor_total), 0);

      this.produtoSelecionado.valor_total = this.produtoSelecionado.valor_total_produtos * this.qtde;
    }
  }

  totalizarCarrinho() {
    this.carrinhoValor = this.carrinho.reduce((sum, current) => (sum + current.valor_total), 0);

    this.validarPrazoPagamentoParcela();
    this.validarPrazoPagamento();
  }

  /* Variação */

  selecionarVariacao(id: string) {
    this.variacaoSelecionada = parseInt(id);
  }
}
